import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

export function ForgotPassword() {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const [skipped, setSkipped] = useState(new Set());
  const [isInternal, setIsInternal] = useState(false);
  const [secretCode, setSecretCode] = useState("");
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [email, setEmail] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const { t, i18n } = useTranslation(["MFA", "Common"]);

  const userState = useSelector((state) => state.user);

  const isStepSkipped = (step) => skipped.has(step);

  const steps = [t("EnterUsername"), t("ValidateCode"), t("EnterNewPassword")];

  const saveNewPassword = async () => {
    if (newPassword1 !== newPassword2) {
      setAlertMessage("Passwords do not match.");
      setAlertType("error");
      setAlertOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/SaveNewPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({ username, password: newPassword1 }),
        }
      );
      if (response.ok) {
        setAlertMessage("Password reset successful!");
        setAlertType("success");
        setAlertOpen(true);
      } else {
        setAlertMessage("Failed to save the new password.");
        setAlertType("error");
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage("An error occurred while resetting the password.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      IsInternal();
      getEmail();
      if (!isInternal) sendCode();
    } else if (activeStep === 2 && newPassword1 !== newPassword2) {
      setAlertMessage("Passwords do not match.");
      setAlertType("error");
      setAlertOpen(true);
      return;
    } else if (activeStep === 2) {
      saveNewPassword();
    }

    // Mark the current step as completed before moving to the next
    setCompletedSteps((prev) => new Set(prev).add(activeStep));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsInternal(false);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const sendCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/login/ForgotPassword/${username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.text();
      if (response.ok) {
        setSecretCode(data);
        setAlertMessage("Code sent to your email.");
        setAlertType("success");
        setAlertOpen(true);
      } else {
        setAlertMessage("Failed to send the code.");
        setAlertType("error");
        setAlertOpen(true);
      }
    } catch {
      setAlertMessage("Failed to send the code.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const IsInternal = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetIsInternal/${username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setIsInternal(data);
    } catch {
      setAlertMessage("Error checking internal status.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const getEmail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetEmploye/${username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setEmail(data.email);
    } catch {}
  };

  useEffect(() => {
    setIsCodeVerified(false);
    setSecretCode("fdsf");
    if (username) {
      getEmail();
    }
  }, [username]);

  // Automatically check if code is correct and update isCodeVerified
  useEffect(() => {
    if (code.trim() === secretCode.trim() && activeStep === 1) {
      setAlertMessage("Code verified successfully.");
      setAlertType("success");
      setAlertOpen(true);
      setIsCodeVerified(true);
      handleNext();
    } else {
      setIsCodeVerified(false);
    }
  }, [code, secretCode, activeStep]);

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completedSteps.has(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you're finished
            </Typography>
            <Button onClick={handleClose}>{t("Common:Close")}</Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CustomAlert
              open={alertOpen}
              message={alertMessage}
              typeMessage={alertType}
              closeMessage={setAlertOpen}
            />
            {activeStep === 0 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {t("forgotPasswordUsername")}
                </Typography>
                <TextField
                  fullWidth
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </>
            )}
            {activeStep === 1 && (
              <>
                {isInternal ? (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    {t("ForgotPasswordInternal")}
                  </Typography>
                ) : (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {t("EnterVerificationCode")}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Verification Code"
                      variant="outlined"
                      value={code}
                      onChange={handleCodeChange}
                    />
                  </>
                )}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {t("EnterNewPassword")}
                </Typography>
                <TextField
                  fullWidth
                  label={t("NewPassword")}
                  type="password"
                  variant="outlined"
                  value={newPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2, mb: 1 }}
                  fullWidth
                  label={t("RepeatNewPassword")}
                  type="password"
                  variant="outlined"
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("Common:Back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                disabled={activeStep === 1 && !isCodeVerified}
              >
                {activeStep === steps.length - 1
                  ? t("Common:Finish")
                  : t("Common:Next")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./css/ReportItemDashboard.css";
import MyDocument from "../../Common/tools/CreatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FormatDateTime } from "../../../utils/formateDate";
import { useMediaQuery, useTheme } from "@mui/material";

function ReportItemDashboard({
  report: propReport,
  typeActivite,
  nomEmplacement,
  username,
  date,
  dateEvent,
  setReloadReports,
}) {
  const { t } = useTranslation(["Articles", "Common"]);
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const userState = useSelector((state) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Report/GetReport/${propReport.idActivite}/${propReport.idTypeActivite}`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        setReport(data);
      } catch (err) {
        console.error("Failed to fetch report:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [propReport, userState.token]);

  const handleReopen = async () => {
    try {
      const activity = {
        id: propReport.idActivite,
      };
      await fetch(`${process.env.REACT_APP_API_URL}/Report/ReopenReport`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(activity),
      });
      setReloadReports(true);
    } catch (err) {
      console.error("Failed to reopen report:", err);
    }
  };

  return (
    <div className="ReportItemDashboard__main">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: isMobile ? 1 : 2,
        }}
      >
        <Paper elevation={2}>
          <div className="ReportItemDashboard__DashboardReportInfo">
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              <b>{typeActivite}</b>
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {nomEmplacement}
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              <b>{username}</b>
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {FormatDateTime(date)}
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {FormatDateTime(dateEvent)}
            </Box>
            <div className="ReportItemDashboard__DashboardReportButon">
              {userState.roles.find(
                (role) => role === "AdminTI" || role === "ReportAdmin"
              ) && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#FFBC0F", color: "black" }}
                  onClick={handleReopen}
                >
                  {t("Reopen")}
                </Button>
              )}

              {!loading && report && (
                <PDFDownloadLink
                  document={
                    <MyDocument report={report} userLang={userState.lang} />
                  }
                  fileName={`${propReport.idActivite}_${
                    userState.lang === "EN"
                      ? propReport.titleRepportEn
                      : propReport.titleRepportFr
                  }.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#FFBC0F",
                          color: "black",
                          marginLeft: 5,
                        }}
                      >
                        {t("Download")}
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
}

export default ReportItemDashboard;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RecipeCard from "./RecipeCard";
import { Grid, CircularProgress } from "@mui/material";

function RecipesList({ category, setRecipe, setImagesRecipe, selectedRecipe }) {
  const [recipeList, setRecipeList] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchRecipe = async () => {
      setLoadingCategory(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Recipe/GetRecipesByCategory/${category.textEn}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
            credentials: "include",
            signal: signal, // Pass the signal to the fetch request
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const sortedData = data.sort((a, b) => 
          userState.lang === "FR" ? a.recette.nomFr.localeCompare(b.recette.nomFr) : a.recette.nomEn.localeCompare(b.recette.nomEn)
        );
        setRecipeList(sortedData);
        setLoadingCategory(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error("Error fetching Recipes:", error);
        }
      }
    };

    fetchRecipe();

    // Cleanup function to abort the fetch request if the component unmounts or category changes
    return () => {
      controller.abort();
      setLoadingCategory(true);
    };
  }, [category]);
    
  useEffect(() => {
    /* Sort RecipeList when Language is changed */
    const sortedData = [...recipeList].sort((a, b) => 
      userState.lang === "FR" ? a.recette.nomFr.localeCompare(b.recette.nomFr) : a.recette.nomEn.localeCompare(b.recette.nomEn)
    );
    setRecipeList(sortedData);
  }, [userState]);

  return (
    <div>
      <Grid container className="RecipeList">
      {loadingCategory ? (
            <CircularProgress
              className="CategoryLoadingSpinner"
              size="2.5rem"
            />
          ):(
            recipeList.map((recipe) => (
              <>
                <Grid item sm={1} className="RecipeCard" key={recipe.id}>
                  <RecipeCard
                    recipe={recipe.recette}
                    selectedRecipe={selectedRecipe}
                    onClick={setRecipe}
                    setImagesRecipe={setImagesRecipe}
                    images={recipe.images}
                  />
                </Grid>
              </>
            ))
          )}
        
      </Grid>
    </div>
  );
}

export default RecipesList;

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import Checkbox from "@mui/material/Checkbox";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

function TabWebTime(props) {
  const { t, i18n } = useTranslation(["Articles"]);

  const [dinein, setDinein] = useState();
  const [takeout, setTakeout] = useState();
  const [delivery, setDelivery] = useState();
  const [meetingRoom, setMeetingRoom] = useState();
  const [terrace, setTerrace] = useState();

  const [mondayOpen, setMondayOpen] = useState();
  const [tuesdayOpen, setTuesdayOpen] = useState();
  const [wednesdayOpen, setWenesdayOpen] = useState();
  const [thursdayOpen, setThursdayOpen] = useState();
  const [fridayOpen, setFridayOpen] = useState();
  const [saturdayOpen, setSaturdayOpen] = useState();
  const [sundayOpen, setSundayOpen] = useState();

  const [mondayClose, setMondayClose] = useState();
  const [tuesdayClose, setTuesdayClose] = useState();
  const [wednesdayClose, setWenesdayclose] = useState();
  const [thursdayClose, setThursdayClose] = useState();
  const [fridayClose, setFridayClose] = useState();
  const [saturdayClose, setSaturdayClose] = useState();
  const [sundayClose, setSundayClose] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const defaultValue = dayjs("2022-04-17T15:30");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const userState = useSelector((state) => state.user);

  const handleSaveRestaurant = async () => {
    var dataWebTime = {
      noEmplacement: props.emplacement.noEmplacement,
      mondayOpen: mondayOpen,
      tuesdayOpen: tuesdayOpen,
      wednesdayOpen: wednesdayOpen,
      thursdayOpen: thursdayOpen,
      fridayOpen: fridayOpen,
      saturdayOpen: saturdayOpen,
      sundayOpen: sundayOpen,
      mondayClose: mondayClose,
      tuesdayClose: tuesdayClose,
      wednesdayClose: wednesdayClose,
      thursdayClose: thursdayClose,
      fridayClose: fridayClose,
      saturdayClose: saturdayClose,
      sundayClose: sundayClose,

      dinein: dinein,
      takeout: takeout,
      delivery: delivery,
      meetingRoom: meetingRoom,
      terrace: terrace,

      username: userState.username,
      tab: "Time",
    };

    fetch(`emplacement/SaveLocation`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataWebTime),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("location saved");
          setShowAlert(true);
        }
        if (data == "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, location not saved");
          setShowAlert(true);
        }
      });
  };

  useEffect(() => {
    if (props.emplacement) {
      setDelivery(props.emplacement.delivery);
      setDinein(props.emplacement.dinein);
      setTakeout(props.emplacement.takeout);
      setMeetingRoom(props.emplacement.meeting_room);
      setTerrace(props.emplacement.terrace);

      setMondayOpen(dayjs(props.emplacement.monday_open, "HH:mm:ss").format());
      setMondayClose(
        dayjs(props.emplacement.monday_close, "HH:mm:ss").format()
      );
      setTuesdayOpen(
        dayjs(props.emplacement.tuesday_open, "HH:mm:ss").format()
      );
      setTuesdayClose(
        dayjs(props.emplacement.tuesday_close, "HH:mm:ss").format()
      );
      setWenesdayOpen(
        dayjs(props.emplacement.wednesday_open, "HH:mm:ss").format()
      );
      setWenesdayclose(
        dayjs(props.emplacement.wednesday_close, "HH:mm:ss").format()
      );
      setThursdayOpen(
        dayjs(props.emplacement.thursday_open, "HH:mm:ss").format()
      );
      setThursdayClose(
        dayjs(props.emplacement.thursday_close, "HH:mm:ss").format()
      );
      setFridayOpen(dayjs(props.emplacement.friday_open, "HH:mm:ss").format());
      setFridayClose(
        dayjs(props.emplacement.friday_close, "HH:mm:ss").format()
      );
      setSaturdayOpen(
        dayjs(props.emplacement.saturday_open, "HH:mm:ss").format()
      );
      setSaturdayClose(
        dayjs(props.emplacement.saturday_close, "HH:mm:ss").format()
      );
      setSundayOpen(dayjs(props.emplacement.sunday_open, "HH:mm:ss").format());
      setSundayClose(
        dayjs(props.emplacement.sunday_close, "HH:mm:ss").format()
      );
    }
  }, [props.emplacement]);

  return (
    <>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <div>
            <Checkbox
              {...label}
              checked={dinein || ""}
              onChange={() => {
                setDinein(!dinein);
              }}
            />{" "}
            {t("DineIn")}
            <Checkbox
              {...label}
              checked={delivery || ""}
              onChange={() => {
                setDelivery(!delivery);
              }}
            />{" "}
            {t("Delivery")}
            <Checkbox
              {...label}
              checked={takeout || ""}
              onChange={() => {
                setTakeout(!takeout);
              }}
            />{" "}
            {t("TakeOut")}
            <Checkbox
              {...label}
              checked={meetingRoom || ""}
              onChange={() => {
                setMeetingRoom(!meetingRoom);
              }}
            />{" "}
            {t("MeetingRoom")}
            <Checkbox
              {...label}
              checked={terrace || ""}
              onChange={() => {
                setTerrace(!terrace);
              }}
            />{" "}
            {t("Terace")}
          </div>
          <Grid container spacing={3} sx={{ placeContent: "center" }}>
            <Grid item xs={12} sm={8}>
              <h4>{t("Monday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={mondayOpen || ""} />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={mondayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Tuesday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={tuesdayOpen || ""} />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={tuesdayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Wednesday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={wednesdayOpen || ""} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={wednesdayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Thursday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={thursdayOpen || ""} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={thursdayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Friday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={fridayOpen || ""} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={fridayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Saturday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={saturdayOpen || ""} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={saturdayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4>{t("Sunday")}</h4>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="From" value={sundayOpen || ""} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField label="To" value={sundayClose || ""} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSaveRestaurant}>
                Save Web site info
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </>
  );
}

export default TabWebTime;

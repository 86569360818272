import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export function CustomAlert({
  open,
  message,
  typeMessage,
  closeMessage,
  showUndo = false,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    closeMessage(false);
  };

  const action = (
    <React.Fragment>
      {showUndo && (
        <Button color="primary" size="small" onClick={handleClose}>
          UNDO
        </Button>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={action}
    >
      <Alert
        onClose={handleClose}
        severity={typeMessage === "success" ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

// Define Prop Types
CustomAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  typeMessage: PropTypes.oneOf(["success", "error"]),
  closeMessage: PropTypes.func.isRequired,
  showUndo: PropTypes.bool,
};

export default CustomAlert;

import React, { useState } from "react";
import { Box } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./css/ReportsLeftBar.css";

function ReportsLeftBar({ reports, setReport }) {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation(["Articles"], ["Common"]);

  const userState = useSelector((state) => state.user);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  return (
    <div className="FormRepport__Treeview">
      <Box
        sx={{
          height: 270,
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
        }}
      >
        <TreeView
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
        >
          <TreeItem nodeId={500} label={t("My reports")}>
            {reports &&
              reports.map((report, i) => (
                <TreeItem
                  nodeId={50 + i++}
                  label={
                    userState.lang === "EN"
                      ? report.titleRepportEn +
                        " (" +
                        report.nomEmplacement +
                        ")"
                      : report.titleRepportFr +
                        " (" +
                        report.nomEmplacement +
                        ")"
                  }
                  onClick={(e) => setReport(report)}
                />
              ))}
          </TreeItem>
        </TreeView>
      </Box>
    </div>
  );
}

export default ReportsLeftBar;

import React, { useEffect, useState, useCallback } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";

import "./css/UploadedAttachments.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function UploadedAttachments({ article, readOnly, reloadArticle }) {
  const [dense] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);

  const getAttachments = useCallback(async () => {
    if (article) {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/article/GetAttachments/${article.id}`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        setAttachments(data);
        setLoading(false);
      } catch (err) {
        setError(t("ErrorFetchingAttachments"));
        setLoading(false);
      }
    }
  }, [article, userState.token, t]);

  useEffect(() => {
    getAttachments();
  }, [getAttachments, reloadArticle]);

  const handleDelete = async (e) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/article/DeleteAttachment/${e.currentTarget.value}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      // Re-fetch attachments after deletion
      getAttachments();
    } catch (err) {
      setError(t("ErrorDeletingAttachment"));
    }
  };

  const handleOpenFile = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/DownloadAttachment/${e.target.textContent}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = e.target.textContent;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      setError(t("ErrorDownloadingFile"));
    }
  };

  const renderAttachments = useCallback(() => {
    return (
      <Grid item xs={12} md={6}>
        <List dense={dense}>
          {attachments.map((attachment) => (
            <ListItem
              key={attachment.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  value={attachment.id}
                  onClick={handleDelete}
                  disabled={readOnly}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{attachment.lang}</Avatar>
              </ListItemAvatar>
              <Link onClick={handleOpenFile} style={{ cursor: "pointer" }}>
                <ListItemText primary={attachment.fileName} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Grid>
    );
  }, [attachments, dense, handleDelete, handleOpenFile, readOnly]);

  return (
    <div className="UploadedFile__Main">
      <h5>{t("FileUploaded")}</h5>

      {loading ? (
        <p>{t("LoadingFiles")}</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : attachments.length > 0 ? (
        renderAttachments()
      ) : (
        <p>
          <em>{t("NoFilesAttached")}</em>
        </p>
      )}
    </div>
  );
}

export default UploadedAttachments;

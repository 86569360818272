import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Article from "../Article";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalContent = ({ searchArticle }) => {
  const { t } = useTranslation();

  if (searchArticle?.length === 100) {
    return <Typography>{t("PreciseSearch")}</Typography>;
  }

  return searchArticle?.map(
    (article) =>
      article.textFr &&
      article.textFr.length > 0 && (
        <Article key={article.id} article={article} />
      )
  );
};

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      {t("NoResultsFound")}
    </Typography>
  );
};

function SearchArticlesModalResult({ open, close, searchArticle }) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="MemoHebdo__Modal">
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {searchArticle ? (
            <ModalContent searchArticle={searchArticle} />
          ) : (
            <NoResults />
          )}
        </Typography>
      </Box>
    </Modal>
  );
}

export default SearchArticlesModalResult;

import React, { useEffect, useState } from "react";
import ArticleLeftBar from "./ArticlesLeftBar";
import ArticleEditor from "./ArticleEditor";
import { useSelector } from "react-redux";

function WriteArticleView() {
  const [activeArticle, setActiveArticle] = useState();
  const [reloadArticle, setReloadArticle] = useState(false);
  const [time, setTime] = useState(Date.now());

  const userState = useSelector((state) => state.user);

  const handleSelectArticle = async (e) => {
    if (e != null) {
      try {
        // Send a POST request to save the article
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Article/GetArticleById/${e.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        // Check if the response is OK (status code 2xx)
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        // Parse the JSON response
        const result = await response.json();
        setActiveArticle(result);
        console.log(result);
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error saving article:", error);
        // Optionally display an error message to the user
      }
      if (e.employeLock && e.employeLock != userState.username) {
        alert(
          `This article is locked by ${e.employeLock} and can be incomplete`
        );
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 2000);

    return () => {
      setReloadArticle(true);
      clearInterval(interval);
    };
  }, [time]);

  return (
    <div className="EditArticle__Editor">
      <ArticleLeftBar
        selectArticle={handleSelectArticle}
        reloadArticle={reloadArticle}
        setReload={setReloadArticle}
      />
      <ArticleEditor
        article={activeArticle}
        setArticle={setActiveArticle}
        reloadArticle={reloadArticle}
        setReloadArticle={setReloadArticle}
      />
    </div>
  );
}

export default WriteArticleView;

import React, { useEffect, useState, useMemo } from "react";

import ReportItemDashboard from "./ItemDashboardReport";
import SearchReport from "../SearchReports/SearchReports";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";

function DashboardReport() {
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [reloadReports, setReloadReports] = useState(false);

  const userState = useSelector((state) => state.user);
  const userLang = useSelector((state) => state.user.lang);

  useEffect(() => {
    fetchCompletedReports();
  }, [reloadReports]); // Empty dependency to ensure it runs once when the component mounts

  const fetchCompletedReports = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Report/GetCompletedReports/${userState.username}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch reports");
      }
      const data = await response.json();
      setReports(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const paginatedReports = useMemo(() => {
    const startIndex = (page - 1) * 10;
    const endIndex = page * 10;
    return reports.slice(startIndex, endIndex);
  }, [reports, page]);

  const totalPages = useMemo(() => {
    return Math.ceil(reports.length / 10);
  }, [reports.length]);

  return (
    <div>
      <SearchReport setReports={setReports} />

      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        paginatedReports.map((report) => (
          <ReportItemDashboard
            key={report.id} // Assuming each report has a unique ID
            nomEmplacement={report.nomEmplacement}
            date={report.dateComplete}
            dateEvent={report.dateEvenement}
            username={report.username}
            typeActivite={
              userLang === "EN" ? report.titleRepportEn : report.titleRepportFr
            }
            report={report}
            setReloadReports={setReloadReports}
          />
        ))
      )}

      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
        />
      </Stack>
    </div>
  );
}

export default DashboardReport;

import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  Stack,
  TextField,
  FormControl,
  Autocomplete,
  Paper,
  Chip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./css/SearchReports.css";

function SearchReports({ setReports }) {
  const { t } = useTranslation(["Articles"]);
  const userState = useSelector((state) => state.user);

  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [word, setWord] = useState("");
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [restaurant, setRestaurant] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [typeActivities, setTypeActivities] = useState([]);
  const [typeActivity, setTypeActivity] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    fetchRestaurants();
    fetchActivities();
    fetchInternalEmployees();
  }, []);

  const fetchRestaurants = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setRestaurants(data);
  };

  const fetchActivities = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetActivities`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setTypeActivities(data);
  };

  const fetchInternalEmployees = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/employee/GetInternalEmploye`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setEmployees(data);
  };

  const handleSearch = async () => {
    const searchParams = {
      restaurant,
      typeActivity: typeActivity?.id,
      endPublishDate,
      publishDate,
      Employe: employee,
      username: userState.username,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Report/SearchReport`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(searchParams),
      }
    );
    const data = await response.json();
    setReports(data);
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setSearchList((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && word) {
      setSearchList((prev) => [...prev, word]);
      setWord("");
      ref.current.value = "";
    }
  };

  const handleChangeTypeActivities = (event, newValue) => {
    const selectedActivity = typeActivities.find(
      (activity) =>
        (userState.lang === "EN" ? activity.textEn : activity.textFr) ===
        newValue
    );
    setTypeActivity(selectedActivity);
  };

  return (
    <div>
      <Stack
        spacing={2}
        direction="row"
        className="Filter__Search"
        sx={{ m: 2, justifyContent: "center" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("From")}
            value={publishDate ? dayjs(publishDate) : null}
            onChange={(newValue) => setPublishDate(newValue)}
            sx={{ width: "200px" }}
          />
          <DatePicker
            label={t("To")}
            value={endPublishDate ? dayjs(endPublishDate) : null}
            onChange={(newValue) => setEndPublishDate(newValue)}
            sx={{ width: "200px" }}
          />
        </LocalizationProvider>

        <FormControl sx={{ width: "20%" }}>
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => option.username}
            onChange={(event, newValue) => setEmployee(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={t("Employee")} />
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <Autocomplete
            options={restaurants.map((r) => r.nomEmplacement)}
            onChange={(event, newValue) => setRestaurant(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={t("Restaurants")} />
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <Autocomplete
            id={t("Report")}
            options={typeActivities.map((activity) =>
              userState.lang === "EN" ? activity.textEn : activity.textFr
            )}
            onChange={handleChangeTypeActivities}
            sx={{ marginRight: "10px", marginTop: "15px" }}
            value={
              typeActivity
                ? userState.lang === "EN"
                  ? typeActivity.textEn
                  : typeActivity.textFr
                : ""
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Report")} />
            )}
          />
        </FormControl>

        <Button
          variant="contained"
          sx={{ backgroundColor: "#FFBC0F", color: "black" }}
          onClick={handleSearch}
        >
          {t("Search")}
        </Button>
      </Stack>
    </div>
  );
}

export default SearchReports;

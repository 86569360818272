import React, { useState } from "react";
import ConnectionStatus from "../../../utils/ConnectionStatus";
import { useSelector } from "react-redux";
import ReportForm from "./ReportForm";

import "./css/ReportEditor.css";
import { Button, Modal, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SubmitReport from "./SubmitReport";

function ReportEditor({
  report,
  setReport,
  restaurant,
  setRestaurant,
  setTypeActivity,
  typeActivity,
}) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { t } = useTranslation(["Report", "Common"]);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);

  const userState = useSelector((state) => state.user);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleStatusChange = (status) => {
    setIsOnline(status);
  };

  const handleNewReport = async () => {
    if (typeActivity && restaurant) {
      var newActivity = {
        id: 0,
        IdTypeActivite: typeActivity.id,
        DateComplete: null,
        DateEvenement: null,
        location: restaurant,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Report/NewActivity`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
            body: JSON.stringify(newActivity),
          }
        );

        const data = await response.json();
        console.log(data);
        setReport({ ...data }); // Ensures report state is updated
      } catch (error) {
        console.error("Error creating new activity:", error);
      }
    }
  };

  const handleDeleteReport = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Report/DeleteReport/${report.idActivite}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      // Check for response status and handle accordingly
      if (response.ok) {
        // Successful deletion
        setReport(null); // Clear or update the report state
        setRestaurant(null);
        setTypeActivity(null);
        alert("Report deleted successfully.");
      } else if (response.status === 404) {
        alert("Report not found.");
      } else if (response.status === 409) {
        const errorText = await response.text();
        alert("Error: " + errorText);
      } else {
        const errorData = await response.text(); // Get the error message if present
        throw new Error(errorData || "Failed to delete report");
      }
    } catch (error) {
      console.error("Error deleting report:", error);
      alert("An error occurred while deleting the report: " + error.message);
    }
  };

  const handleOpenModalSubmit = () => setOpenModalSubmit(true);
  const handleCloseModalSubmit = () => {
    setOpenModalSubmit(false);
    setReport(null);
    setRestaurant(null);
    setTypeActivity(null);
  };

  return (
    <div className="FormRepport__Question">
      <ConnectionStatus onStatusChange={handleStatusChange} />
      <Button onClick={handleNewReport}>{t("NewReport")}</Button>
      <Button onClick={handleDeleteReport} disabled={report ? false : true}>
        {t("Delete")}
      </Button>
      {isOnline ? (
        <>
          {report &&
            report?.listeQuestions?.map((question) => (
              <ReportForm
                key={question.id}
                question={question}
                idActivite={report.idActivite}
                answers={report.listeReponses}
              />
            ))}
          {report && report.idActivite && (
            <Button variant="contained" onClick={handleOpenModalSubmit}>
              {t("Submit")}
            </Button>
          )}
        </>
      ) : (
        "Your offline !"
      )}
      {report && (
        <Modal
          open={openModalSubmit}
          onClose={handleCloseModalSubmit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Submission
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <SubmitReport
                location={restaurant}
                idActivite={report.idActivite}
                close={handleCloseModalSubmit}
              />
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default ReportEditor;

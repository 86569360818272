import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";

import BreadCrumbsIcon from "../../resources/RecipesIcons/BreadCrumbsIcon.png";

function CustomBreadcrumbs({
  category,
  recipe,
  setRecipe,
  setCategory,
  setSelectedRecipe,
}) {
  const userState = useSelector((state) => state.user);
  const handleHomeClick = (event) => {
    event.preventDefault();
    setRecipe(null); // Reset recipe
    setCategory(null); // Reset category
    setSelectedRecipe(null);
    console.info("Navigated to home");
  };

  const handleCategoryClick = (event) => {
    event.preventDefault();
    setRecipe(null); // Reset recipe
    setSelectedRecipe(null);
    console.info(`Navigated to category: ${category.textEn}`);
  };
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <img src={BreadCrumbsIcon} />
        <Link
          underline="hover"
          color="inherit"
          href="/"
          onClick={handleHomeClick}
        >
          {userState.lang == "FR" ? "Categories" : "Categories"}
        </Link>
        {category && (
          <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
            onClick={handleCategoryClick}
          >
            {userState.lang == "FR" ? category.textFr : category.textEn}
          </Link>
        )}
        {recipe && (
          <Typography sx={{ color: "text.primary" }}>
            {userState.lang == "FR" ? recipe.nomFr : recipe.nomEn}
          </Typography>
        )}
      </Breadcrumbs>
    </div>
  );
}

export default CustomBreadcrumbs;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { useSelector } from "react-redux";

import IMG_SOLEIL from "../../resources/RecipesIcons/IMG_SOLEIL.jpg";
import { useState } from "react";
import { useEffect } from "react";

function RecipeCard({
  recipe,
  images,
  video,
  onClick,
  setImagesRecipe,
  selectedRecipe,
}) {
  const [isActive, setIsActive] = useState(false);
  const userState = useSelector((state) => state.user);

  // Check if the image is a valid byte array
  const imageSrc =
  images && Array.isArray(images) && images.length > 0
      ? `data:image/png;base64,${images[0].images[0]}`
      : IMG_SOLEIL; // Provide a valid path for placeholder image

  const videoSrc =
    video && Array.isArray(video) && video.length > 0
      ? `data:video/png;base64,${video[0]}`
      : ""; // Provide a valid path for placeholder image

  // Function to handle the click event
  const handleClick = () => {
    setIsActive(!isActive);
    setImagesRecipe(imageSrc);
    onClick(recipe); // Pass the clicked recipe to the onClick handler
  };

  useEffect(() => {
    if (recipe && selectedRecipe && selectedRecipe.id === recipe.id) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectedRecipe, recipe]);

  return (
    <Card
      sx={{ maxWidth: 200 }}
      className={`RecipesCard ${isActive ? "active" : ""}`}
    >
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="80"
          image={imageSrc}
          alt={userState.lang == "FR" ? recipe.nomFr : recipe.nomEn}
        />
        <CardContent className="RecipeCardContent">
          <Typography gutterBottom variant="h6" component="h4">
            {userState.lang == "FR" ? recipe.nomFr : recipe.nomEn}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default RecipeCard;

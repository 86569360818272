import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

function TabTenant(props) {
  const [lessorName, setLessorName] = useState();
  const [adress, setAdress] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [fax, setFax] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [optionRenew, setOptionRenew] = useState();
  const [noticePeriod, setNoticePeriod] = useState();
  const [dateEnding, setDateEnding] = useState();
  const [dateBeginning, setDateBeginning] = React.useState(dayjs(""));

  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    if (props.emplacement) {
      setLessorName(props.emplacement.nomBailleur);
      setAdress(props.emplacement.adresseBailleur);
      setPhoneNumber(props.emplacement.telAffaireBailleur);
      setFax(props.emplacement.faxBailleur);
      setEmail(props.emplacement.mailAffaireBailleur);
      setContact(props.emplacement.contactBailleur);
      setDateBeginning(props.emplacement.dateBailEffectif);
      setDateEnding(props.emplacement.dateBailExpiration);
      setOptionRenew(props.emplacement.optionRenouvelerBailleur);
      setNoticePeriod(props.emplacement.periodeAvisBailleur);
    } else {
      setLessorName("");
      setAdress("");
      setPhoneNumber("");
      setFax("");
      setEmail("");
      setContact("");
      setDateBeginning("");
      setDateEnding("");
      setOptionRenew("");
      setNoticePeriod("");
    }
  }, [props.emplacement]);

  const handleSaveRestaurant = async () => {
    var dataLessor = {
      noEmplacement: props.emplacement.noEmplacement,
      lessorName: lessorName,
      adress: adress,
      phoneNumber: phoneNumber,
      fax: fax,
      email: email,
      contact: contact,
      dateBeginning: dateBeginning,
      dateEnding: dateEnding,
      optionRenew: optionRenew,
      noticePeriod: noticePeriod,
      username: userState.username,
      tab: "Lessor",
    };

    fetch(`emplacement/SaveLocation`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataLessor),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  return (
    <>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NomBailleur"
                label="Nom Bailleur"
                name="NomBailleur"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={lessorName || ""}
                onChange={(e) => setLessorName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Adresse"
                label={t("Adress")}
                name="Adresse"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={adress || ""}
                onChange={(e) => setAdress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="TelAffaire"
                name="TelAffaire"
                label="Tel Affaire"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={phoneNumber || ""}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Fax"
                name="Fax"
                label={t("Fax")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={fax || ""}
                onChange={(e) => setFax(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="CourrielAffaire"
                name="CourrielAffaire"
                label="Courriel Affaire"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Contact"
                label={t("Contact")}
                name="Contact"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={contact || ""}
                onChange={(e) => setContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("BeginLeaseDate")}
                    id="Date ouverture"
                    name="Date ouverture"
                    fullWidth
                    size="medium"
                    autoComplete="off"
                    variant="outlined"
                    value={dayjs(dateBeginning) || ""}
                    onChange={(newValue) => setDateBeginning(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("EndLeaseDate")}
                    id="EndLeaseDate"
                    name="EndLeaseDate"
                    fullWidth
                    size="medium"
                    autoComplete="off"
                    variant="outlined"
                    value={dayjs(dateEnding) || ""}
                    onChange={(newValue) => setDateEnding(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="optionRenouvellement"
                name="optionRenouvellement"
                label={t("OptionRenew")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={optionRenew || ""}
                onChange={(e) => setOptionRenew(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="periodePreavis"
                label={t("NoticePeriod")}
                name="periodePreavis"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={noticePeriod || ""}
                onChange={(e) => setNoticePeriod(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSaveRestaurant}>
                Save Tenant
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </>
  );
}

export default TabTenant;

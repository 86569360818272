import React, { Component } from "react";
import MenuBar from "./MenuBar";
//import { MenuBar } from './MenuBar';

export class Layout extends Component {
  render() {
    return (
      <div>
        <MenuBar />
        {this.props.children}
      </div>
    );
  }
}

import React, { useEffect, useRef, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";

import IMG_SOLEIL from "../../resources/RecipesIcons/IMG_SOLEIL.jpg";
import { useTranslation } from "react-i18next";

function Recipe({ recipe, images }) {
  const {
    nomEn,
    nomFr,
    ingredientEn,
    ingredientFr,
    decorationFr,
    decorationEn,
    recetteEn,
    recetteFr,
    ustensileEn,
    ustensileFr,
    portionMinEn,
    portionMinFr,
    emporterFr,
    emporterEn,
    formatVideoFr,
    formatVideoEn,
  } = recipe;

  const [videos, setVideos] = useState();
  const [videoLoading, setVideoLoading] = useState(false); // Loading state for video
  const { t, i18n } = useTranslation(["Recipes"]);
  const abortControllerRef = useRef(null);

  const userState = useSelector((state) => state.user);

  const videoSrc =
    videos &&
    userState.lang === "FR" &&
    videos.videoFr &&
    videos.videoFr.length > 0
      ? `data:video/mp4;base64,${videos.videoFr[0]}`
      : videos && videos.videoEn && videos.videoEn.length > 0
      ? `data:video/mp4;base64,${videos.videoEn[0]}`
      : ""; // Provide a valid path for placeholder video

  const imageList =
    images && Array.isArray(images) && images.length > 0
      ? images.map((image) => `data:images/png;base64,${image.images}`)
      : [IMG_SOLEIL]; // Provide a valid path for placeholder image

  const imageDescList = 
    images && Array.isArray(images) && images.length > 0
      ? images.map((image) => 
          userState.lang === "FR"
          ? image.textFr
          : image.textEn
      ) : '';

  const ingredientsList = useMemo(
    () =>
      userState.lang === "FR"
        ? ingredientFr.split("\n")
        : ingredientEn.split("\n"),
    [userState.lang, ingredientFr, ingredientEn]
  );

  const decorationList = useMemo(
    () =>
      userState.lang === "FR"
        ? decorationFr.split("\n")
        : decorationEn.split("\n"),
    [userState.lang, decorationFr, decorationEn]
  );

  const ustensileList = useMemo(
    () =>
      userState.lang === "FR"
        ? ustensileFr.split("\n")
        : ustensileEn.split("\n"),
    [userState.lang, ustensileFr, ustensileEn]
  );

  const recetteList = useMemo(
    () =>
      userState.lang === "FR" ? recetteFr.split("\n") : recetteEn.split("\n"),
    [userState.lang, recetteEn, recetteFr]
  );

  const reducedPortionList = useMemo(
    () =>
      userState.lang === "FR"
        ? portionMinFr.split("\n")
        : portionMinEn.split("\n"),
    [userState.lang, portionMinEn, portionMinFr]
  );

  const takeoutList = useMemo(
    () =>
      userState.lang === "FR" ? emporterFr.split("\n") : emporterEn.split("\n"),
    [userState.lang, emporterEn, emporterFr]
  );
  // Function to handle when the video has loaded
  const handleVideoLoaded = (e) => {
    setVideoLoading(false);
    e.target.play();
  };

  const fetchVideo = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort any ongoing fetch
    }
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetVideoByRecipe/${recipe.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
          signal: signal, // Pass the signal to the fetch request
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setVideos(data);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error("Error fetching videos:", error);
      }
    } finally {
      setVideoLoading(false);
    }
  };

  const handleClick = () => {
    if (recipe && recipe.id) {
      setVideoLoading(true);
      fetchVideo();
    }
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort fetch on unmount
      }
    };
  }, []);

  return (
    <Box>
      <Box className="RecipeImageColumn">
        <Grid container spacing={2}>
          {/* Image Section */}
          {!videoSrc ? (
            <Grid item xs={12} md={12}>
              <Card className="RecipeMediaContainer">
                {(recipe.formatVideoEn || recipe.formatVideoFr) &&
                  (!videoLoading ? (
                    <div
                      className="RecipeVideoPlayButton"
                      onClick={(e) => handleClick(e)}
                    ></div>
                  ) : (
                    <CircularProgress
                      className="RecipeLoadingSpinner"
                      size="5rem"
                    />
                  ))}
                <CardMedia
                  className="RecipeImage"
                  component="img"
                  image={imageList[0]} // Replace with the actual image path
                  alt={nomEn}
                />
              </Card>
            </Grid>
          ) : (
            <>
              {/* Video Section */}
              <Grid item xs={12} md={12}>
                <Card className="RecipeMediaContainer">
                  <CardContent>
                    {/* Show spinner while video is loading */}
                    <video
                      width="100%"
                      controls
                      preload="auto"
                      onLoadedData={handleVideoLoaded}
                      poster={imageList[0]}
                      src={videoSrc}
                      type="video/mp4"
                    />
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}

          {/* Utensils Section */}
          {ustensileEn && (
            <Grid item xs={6} md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                {t("Utensils")}
              </Typography>
              <ul>
                {ustensileEn &&
                  ustensileList.map((ustensile, index) => (
                    <li key={index}>
                      <Typography variant="body1">{ustensile}</Typography>
                    </li>
                  ))}
              </ul>
            </Grid>
          )}

          {/* Decorations Section */}
          {decorationEn && (
            <Grid item xs={6} md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Decorations
              </Typography>
              <ul>
                {decorationEn &&
                  decorationList.map((decoration, index) => (
                    <li key={index}>
                      <Typography variant="body1">{decoration}</Typography>
                    </li>
                  ))}
              </ul>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box className="RecipeMethodColumn">
        {/* Recipe Name */}
        <Typography variant="h4" component="h1" gutterBottom>
          {userState.lang === "FR" ? nomFr : nomEn}
        </Typography>

        {/* Ingredients Section */}
        {ingredientEn && (
          <Box mt={4}>
            <Typography variant="h5" component="h2" gutterBottom>
              Ingredients
            </Typography>
            <ul>
              {ingredientEn &&
                ingredientsList.map((ingredient, index) => (
                  <li key={index}>
                    <Typography variant="body1">{ingredient}</Typography>
                  </li>
                ))}
            </ul>
          </Box>
        )}

        {/* Method Section */}
        {(recetteFr || recetteEn) && (
          <Box mt={2} className={"RecipeMethod"}>
            <Typography variant="h5" component="h2" gutterBottom>
              Method
            </Typography>
            {recetteList &&
              recetteList.map((recette, index) => (
                <Typography variant="body1">{recette}</Typography>
              ))}
          </Box>
        )}
        {(portionMinEn || portionMinFr) && (
          <Box mt={2}>
            <Typography variant="h5" component="h2" gutterBottom>
              {t("ReducePortion")}
            </Typography>
            {reducedPortionList &&
              reducedPortionList.map((reducedPortion, index) => (
                <Typography variant="body1">{reducedPortion}</Typography>
              ))}
          </Box>
        )}
        {(emporterEn || emporterFr) && (
          <Box mt={2}>
            <Typography variant="h5" component="h2" gutterBottom>
              {t("Takeout")}
            </Typography>
            {takeoutList &&
              takeoutList.map((takeout, index) => (
                <Typography variant="body1">{takeout}</Typography>
              ))}
          </Box>
        )}
        {imageList && imageList.length > 1 && (
          <Box mt={2}>
            {imageList.map((image, index) => (
              index > 0 && (
                <>
                  <Typography variant="h5" component="h2" gutterBottom>
                    { imageDescList[index] }
                  </Typography>
                  <Card className="RecipeExtraImage">
                    <CardMedia
                      component="img"
                      image={image} // Use image directly
                    />
                  </Card>
                </>
              )
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Recipe;

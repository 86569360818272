import React, { useState, useRef } from "react";
import { Stack, TextField, Paper, ListItem, Chip, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Day.js for date manipulation
import { useTranslation } from "react-i18next"; // Translation library
import SearchArticlesModalResult from "./SearchArticlesModalResult";
import { useSelector } from "react-redux";

import "./css/SearchArticle.css";

function SearchArticles() {
  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const { t } = useTranslation(["Articles"]);
  const [searchList, setSearchList] = useState([]);
  const [word, setWord] = useState("");
  const ref = useRef(null);
  const [searchArticle, setSearchArticle] = useState([]);
  const [open, setOpen] = React.useState(false);

  const userState = useSelector((state) => state.user);

  const handleSpace = (e) => {
    if (e.keyCode === 13) {
      setSearchList((prev) => [...prev, word]);
      setWord("");
      ref.current.value = "";
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setSearchList((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleSearch = async () => {
    if (searchList && searchList.length > 0) {
      await handleSearchByKeywords();
    } else {
      await handleSearchByDate();
    }
  };

  const handleSearchByKeywords = async () => {
    var data = {
      username: userState.username,
      keywords: searchList,
      publicationDate: publishDate || null,
      endPublicationDate: endPublishDate || null,
    };
    fetch(`${process.env.REACT_APP_API_URL}/article/SearchArticleByKeyWords`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        if (data.length > 0) {
          setSearchArticle(data);
          setOpen(true);
        } else {
          setSearchArticle();
          setOpen(true);
        }
      });
  };

  const handleSearchByDate = async () => {
    var data = {
      username: userState.username,
      publicationDate: publishDate || null,
      endPublicationDate: endPublishDate || null,
    };

    fetch(`${process.env.REACT_APP_API_URL}/article/SearchArticleByDate`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        setSearchArticle(data);
        setOpen(true);
      });
  };

  return (
    <>
      <Stack spacing={2} direction="row" className="Filter__Search">
        <div className="Filter__date">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="Filter__date__datePicker">
              <label>{t("StartDate")}</label>
              <DatePicker
                defaultValue={dayjs(publishDate)}
                value={dayjs(publishDate)}
                onChange={(newValue) => setPublishDate(newValue)}
              />
              <label>{t("EndDate")}</label>
              <DatePicker
                defaultValue={dayjs(endPublishDate)}
                value={dayjs(endPublishDate)}
                onChange={(newValue) => setEndPublishDate(newValue)}
              />
            </div>
          </LocalizationProvider>
        </div>

        <TextField
          margin="normal"
          name="search"
          label={t("Search")}
          type="text"
          id="search"
          autoComplete="current-password"
          onChange={(e) => setWord(e.currentTarget.value)}
          onKeyDown={handleSpace}
          value={word}
          ref={ref}
        />

        {searchList && (
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {searchList.map((data, index) => (
              <ListItem key={index}>
                <Chip
                  icon={null} // You can customize the icon if necessary
                  label={data}
                  onDelete={() => handleDelete(data)}
                />
              </ListItem>
            ))}
          </Paper>
        )}

        <Button
          variant="contained"
          sx={{ backgroundColor: "#FFBC0F", color: "black" }}
          onClick={handleSearch}
        >
          {t("Search")}
        </Button>
      </Stack>
      <SearchArticlesModalResult
        open={open}
        close={() => setOpen(false)}
        searchArticle={searchArticle}
      />
    </>
  );
}

export default SearchArticles;

import React from "react";
import Frame from "../Common/tools/Frame";

const url = "https://forms.office.com/r/mB4mbC1xwU";

function FranchiseeSurvey() {
  return (
    <div className="App">
      <Frame url={url} />
    </div>
  );
}

export default FranchiseeSurvey;

import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridRowModes,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FullWidthTabs } from "./TabComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import "../../css/CompanyManagement.css";
import CreateShareholder from "./CreateShareholder";
import DetailsShareholder from "./DetailsShareholder";
import CustomAlert from "../Common/Components/CustomAlert";
import { useSelector } from "react-redux";

function CompanyManagement() {
  const { t, i18n } = useTranslation(["Company"], ["Common"]);
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openModalAdresse, setOpenModalAdresse] = useState(false);
  const [openShareholder, setOpenShareholder] = useState();
  const [openDate, setOpenDate] = useState();
  const [closureDate, setClosureDate] = React.useState(dayjs(""));
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [currentNoEmplacement, setCurrentNoEmplacement] = useState();
  const [shareHolders, setShareHolders] = useState([]);
  const [shareHoldersByEmplacement, setShareHoldersByEmplacement] = useState(
    []
  );
  const [currrentShareHolders, setCurrentShareHolders] = useState();
  const [currrentShareHoldersList, setCurrentShareHoldersList] = useState([]);
  const [inputListShareholders, setInputListShareholders] = useState([]);
  const [currentExploitant, setCurrentExploitant] = useState();
  const [idCurrentShareholder, setIdCurrentShareholder] = useState(0);
  const [discountPayments, setDiscountPayments] = useState([]);
  const [discountPayment, setDiscountPayment] = useState();
  const [newAccpPac, setNewAccpPac] = useState();
  const [newCompanyName, setNewCompanyName] = useState();
  const [newOpenDate, setNewOpendate] = useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});

  const [exploitant, setExploitant] = useState();
  const [noMarchand, setNoMarchand] = useState();
  const [noMarchandDC, setNoMarchandDC] = useState();
  const [emplacement, setEmplacement] = useState();
  const [noAccPac, setNoAccPac] = useState();
  const [companyName, setCompanyName] = useState();
  const [passwordDC, setPasswordDC] = useState();
  const [CodeAccesMEV, setCodeAccessMEV] = useState();
  const [PST, setPST] = useState();
  const [GST_HST, setGST_HST] = useState();

  const [adresseShareholder, setAdresseShareholder] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");
  const userState = useSelector((state) => state.user);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 800,
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    { field: "isActive", headerName: "Inactive", width: 50 },
    { field: "firstName", headerName: "Prenom", width: 130, editable: true },
    { field: "lastName", headerName: "Nom", width: 130, editable: true },
    { field: "username", headerName: "Username", width: 130, editable: false },
    { field: "email", headerName: "Email", width: 250, editable: true },
    {
      field: "NoCellulaire",
      headerName: "No Cellulaire",
      width: 130,
      editable: true,
    },
    { field: "langue", headerName: "Langue", width: 80, editable: true },
    {
      field: "department",
      headerName: "Departement",
      width: 130,
      editable: true,
    },
    {
      field: "PourcentageAction",
      headerName: "% Action",
      width: 80,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const findRow = rows.find((row) => row.id == id);

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (findRow.isActive == false) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<ManageAccountsIcon />}
              label="Details"
              onClick={() => handleOpenDetailsShareholder(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PersonOffIcon />}
              label="Delete"
              onClick={handleDeactivateClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<ManageAccountsIcon />}
              label="Delete"
              onClick={handleDeactivateClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PersonIcon />}
              label="Delete"
              onClick={handleActivateClick(id)}
              color="inherit"
            />,
          ];
        }
      },
    },
  ];

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenModalAdresse = () => setOpenModalAdresse(true);
  const handleCloseModalAdresse = () => setOpenModalAdresse(false);

  const handleOpenAddShareHolder = async () => {
    setOpenShareholder(true);
    setIdCurrentShareholder((prev) => prev + 1);
  };
  const handleCloseAddShareHolder = () => setOpenShareholder(false);
  const GetCompanies = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/company/GetCompanies`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  const GetCompaniesWithoutEmplacement = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/company/GetCompaniesWithoutEmplacement`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  useEffect(() => {
    GetCompanies();
    GetEmplacement();

    GetShareHolders();
    GetEscomptePaiement();
  }, []);

  useEffect(() => {
    if (company) {
      GetShareHoldersByEmplacement();
      setNoAccPac(company.noAccpac);
      setCompanyName(company.nom);
      setOpenDate(company.dateOuverture);
      setClosureDate(company.dateFermeture);
      setNoMarchand(company.noMarchand);
      setNoMarchandDC(company.noMarchandDC);
      setPasswordDC(company.passwordDC);
      setDiscountPayment(company.escomptePaiementDetailFr);
      setEmplacement(company.nomEmplacement);
      setCodeAccessMEV(company.AccessCodeMEV);
      setExploitant(company.nomExploitant);
      setCodeAccessMEV(company.accessCodeMEV);
      setPST(company.pst);
      setGST_HST(company.gsT_HST);
    } else {
      setNoAccPac("");
      setCompanyName("");
      setOpenDate("");
      setClosureDate("");
      setNoMarchand("");
      setNoMarchandDC("");
      setPasswordDC("");
      setDiscountPayment("");
      setEmplacement("");
      setCodeAccessMEV("");
      setExploitant("");
      setCodeAccessMEV("");
      setPST("");
      setGST_HST("");
    }
  }, [company]);

  useEffect(() => {
    BuildRows();
  }, [shareHoldersByEmplacement]);

  const BuildRows = () => {
    if (shareHoldersByEmplacement && shareHoldersByEmplacement.length > 0) {
      let rows = [];
      let i = 0;
      shareHoldersByEmplacement.forEach((e) => {
        rows.push({
          id: i++,
          isActive: e.estInactif,
          firstName: e.lastName,
          lastName: e.name,
          username: e.username,
          email: e.email,
          NoCellulaire: e.noCellulaire,
          langue: e.language,
          department: e.department.textEn,
          lastLogin: e.lastLogin,
          PourcentageAction: e.pourcentageAction,
          action: "",
        });
      });

      setRows(rows);
    } else {
      setRows([]);
    }
  };

  const handleChangeCompany = (e) => {
    //setCompany();
    setShareHoldersByEmplacement();
    setRows([]);
    if (companies && companies.length > 0) {
      setCompany(
        companies.filter((c) => e.target.textContent.includes(c.nom))[0]
      );
      setDiscountPayment(
        companies.filter((c) => e.target.textContent.includes(c.nom))[0]
          .escomptePaiementDetailFr
      );
    }
  };

  const GetEmplacement = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetRestaurants`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        var restoWithoutName = {
          noEmplacement: -1,
          nomEmplacement: "Sans Emplacement",
          paysEn: null,
          paysFr: null,
          provinceEn: null,
          provinceFr: null,
          regionEn: null,
          regionFr: null,
        };
        data.unshift(restoWithoutName);
        setRestaurants(data);
      });
  };

  const GetShareHoldersByEmplacement = async () => {
    if (company && company.noCompagnie) {
      fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetShareHoldersByEmplacement/${company.noCompagnie}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setShareHoldersByEmplacement(data);
        });
    }
  };

  const GetShareHolders = async () => {
    fetch(`employe/GetShareHolders`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setShareHolders(data);
      });
  };

  const GetEscomptePaiement = async () => {
    fetch(`company/GetEscomptePaiement`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setDiscountPayments(data);
      });
  };

  const FormNewCompany = () => {
    return (
      <>
        <h5>{t("General Information")}</h5>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("StoreNumber")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="No Accpac"
                name="No Accpac"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={newAccpPac}
                onChange={(e) => setNewAccpPac(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("CompanyName")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="Nom"
                name="Nom"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                {t("OpenDate")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date ouverture"
                  id="Mot de passe DC"
                  name="Mot de passe DC"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={newOpenDate}
                  onChange={(newValue) => setNewOpendate(newValue)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <h5>{t("Shareholders")}</h5>
        <Box sx={{ padding: 5 }}>
          <CreateShareholder
            shareholders={shareHolders}
            shareholdersList={setCurrentShareHoldersList}
          />
        </Box>
      </>
    );
  };

  const handleNewCompany = async (e) => {
    var dataCompany = {
      shareholders: currrentShareHoldersList,
      NoAccpac: newAccpPac,
      nom: newCompanyName,
      dateOuverture: newOpenDate,
    };

    fetch(`${process.env.REACT_APP_API_URL}/company/CreateCompany`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataCompany),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        alert(data);
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("Company created");
          setShowAlert(true);
        }
        if (data == "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, company not created");
          setShowAlert(true);
        }
        GetEmplacement();
        GetCompanies();
        handleClose();
      });
  };
  const handleSaveCompany = async () => {
    const dataCompany = {
      noAccpac: noAccPac,
      dateOuverture: openDate,
      dateFermeture: closureDate,
      nomExploitant: exploitant,
      NoMarchand: noMarchand,
      noMarchandDC: noMarchandDC,
      noEmplacement: emplacement,
      nom: companyName,
      PasswordDC: passwordDC,
      EscomptePaiementDetailFr: discountPayment,
      AccessCodeMEV: CodeAccesMEV,
      PST: PST,
      GST_HST: GST_HST,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company/SaveCompany`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(dataCompany),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save company");
      }

      const data = await response.json();

      if (data === "succes") {
        setTypeMessage("succes");
        setMessage("Company saved successfully");
      } else {
        setTypeMessage("echec");
        setMessage("There was an error, company not saved");
      }
      setShowAlert(true);

      GetEmplacement();
      GetCompanies();
    } catch (error) {
      console.error("Error saving company:", error);
      setTypeMessage("echec");
      setMessage("Failed to save company due to network error");
      setShowAlert(true);
    }
  };

  const TabCompany = () => {
    return (
      <>
        <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
          <Box sx={{ padding: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="No Accpac"
                  label={t("StoreNumber")}
                  name="No Accpac"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={noAccPac || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setNoAccPac(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nom"
                  label={t("CompanyName")}
                  name="Nom"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={companyName || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="Emplacement">{t("Location")}</InputLabel>
                  <Select
                    key={emplacement}
                    labelId="Emplacement"
                    id="Emplacement"
                    label={t("Location")}
                    disabled={company ? !company.estActif : true}
                    defaultValue={emplacement}
                    value={emplacement}
                    onChange={(e) => setEmplacement(e.target.value)}
                  >
                    {restaurants.map((r, i) => (
                      <MenuItem key={i} value={r.nomEmplacement}>
                        {r.nomEmplacement}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="Exploitant">{t("Operator")}</InputLabel>
                  <Select
                    key={exploitant}
                    labelId="Exploitant"
                    label="Exploitant"
                    id="Exploitant"
                    disabled={company ? !company.estActif : true}
                    value={exploitant}
                    defaultValue={exploitant}
                    onChange={(e) => setExploitant(e.target.value)}
                  >
                    {shareHoldersByEmplacement &&
                      shareHoldersByEmplacement.map((s, i) => (
                        <MenuItem
                          key={i}
                          value={
                            s.name + " " + s.lastName + " (" + s.username + ")"
                          }
                        >
                          {s.name + " " + s.lastName + " (" + s.username + ")"}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date ouverture"
                      id="Date ouverture"
                      name="Date ouverture"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      value={dayjs(openDate)}
                      onChange={(newValue) => setOpenDate(newValue)}
                      disabled={company ? !company.estActif : true}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date fermeture"
                      id="Mot de passe DC"
                      name="Mot de passe DC"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      value={dayjs(closureDate)}
                      onChange={(newValue) => setClosureDate(newValue)}
                      disabled={company ? !company.estActif : true}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="No marchand"
                  label="No Marchand"
                  name="No marchand"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={noMarchand || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setNoMarchand(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="No marchand DC"
                  label="No marchand DC"
                  name="No marchand DC"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={noMarchandDC || ""}
                  onChange={(e) => setNoMarchandDC(e.target.value)}
                  disabled={company ? !company.estActif : true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="AccessCodeMEV"
                  label="Code acces MEV"
                  name="AccessCodeMEV"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={CodeAccesMEV || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setCodeAccessMEV(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Mot de passe DC"
                  name="Mot de passe DC"
                  label="Mot de passe DC"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={passwordDC || ""}
                  onChange={(e) => setPasswordDC(e.target.value)}
                  disabled={company ? !company.estActif : true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="GST/HST"
                  label="GST/HST"
                  name="GST/HST"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={GST_HST || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setGST_HST(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="PST"
                  name="PST"
                  label="PST"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={PST || ""}
                  disabled={company ? !company.estActif : true}
                  onChange={(e) => setPST(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="EscomptePaiement">
                    {t("PaymentDiscount")}
                  </InputLabel>
                  <Select
                    key={discountPayment}
                    labelId="EscomptePaiement"
                    label="Escompte paiement"
                    id="EscomptePaiement"
                    disabled={company ? !company.estActif : true}
                    value={discountPayment}
                    defaultValue={
                      company ? company.escomptePaiementDetailFr : ""
                    }
                    onChange={(e) => setDiscountPayment(e.target.value)}
                  >
                    {discountPayments.map((d, i) => (
                      <MenuItem key={i} value={d.nomFr}>
                        {d.nomFr}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {company && company.estActif ? (
                  <Button variant="contained" onClick={handleSaveCompany}>
                    Save
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </>
    );
  };
  const TabShareHolders = () => {
    return (
      <>
        {company ? (
          <Button onClick={handleOpenAddShareHolder}>Add shareholder</Button>
        ) : (
          ""
        )}

        <Modal
          open={openShareholder}
          onClose={handleCloseAddShareHolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="CompanyManagement__Modal">
            {
              <CreateShareholder
                shareholders={shareHolders}
                shareholdersList={setCurrentShareHoldersList}
              />
            }
            <Button onClick={handleNewShareholder}>{t("Save")}</Button>
          </Box>
        </Modal>
        <DataGrid
          editMode="row"
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "isActive",
                    operator: "is",
                    value: "false",
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                isActive: false,
              },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          sx={{ m: 2 }}
        />
      </>
    );
  };

  const handleChangeEmplacement = (event) => {
    setCompany();
    setShareHoldersByEmplacement();
    setRows([]);
    setRestaurant(event.target.textContent);
    if (
      restaurants &&
      event.target.textContent !== "" &&
      event.target.textContent !== "Sans Emplacement"
    ) {
      setCurrentNoEmplacement(
        restaurants.filter(
          (r) => r.nomEmplacement == event.target.textContent
        )[0].noEmplacement
      );
    } else if (event.target.textContent == "Sans Emplacement") {
      setCurrentNoEmplacement();
      GetCompaniesWithoutEmplacement();
    } else {
      setCurrentNoEmplacement();
      GetCompanies();
    }
  };

  useEffect(() => {
    GetCompagniesByEmplacement();
  }, [currentNoEmplacement]);

  const GetCompagniesByEmplacement = async () => {
    fetch(`company/GetCompaniesByEmplacement/${currentNoEmplacement}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const handleDeactivateClick = (id) => () => {
    rows.filter((row) => row.id == id)[0].isInactive = true;
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const handleActivateClick = (id) => () => {
    rows.filter((row) => row.id == id)[0].isInactive = false;
    processRowUpdate(rows.filter((row) => row.id == id)[0]);
  };

  const GetAdresse = async (row) => {
    var dataShareholder = {
      username: row.username,
    };

    fetch(`employe/GetAdresse`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataShareholder),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setAdresseShareholder(data);
      });
  };

  const handleOpenDetailsShareholder = (id) => {
    const row = rows.filter((row) => row.id == id)[0];
    GetAdresse(row);
    handleOpenModalAdresse();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    /**********************/
    handleSaveShareholder(updatedRow);
    /**********************/
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleNewShareholder = async () => {
    if (company.noCompagnie) {
      var dataShareholders = {
        shareholders: currrentShareHoldersList,
        noCompany: company.noCompagnie,
      };

      fetch(`company/CreateShareholders`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(dataShareholders),
      })
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          if (data == "succes") {
            setTypeMessage("succes");
            setMessage("shareholder created successfully");
            setShowAlert(true);
          } else {
            setTypeMessage("echec");
            setMessage("There is an error, shareholder not created");
            setShowAlert(true);
          }
        });
    }
  };

  const handleSaveShareholder = async (shareholder) => {
    var data = {
      shareholder: shareholder,
      noCompany: company.noCompagnie,
    };

    fetch(`employe/SaveShareholder`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("shareholder saved successfully");
          setShowAlert(true);
        } else {
          setTypeMessage("echec");
          setMessage("There is an error, shareholder not saved");
          setShowAlert(true);
        }
      });
  };

  return (
    <>
      <div>
        <Button onClick={handleOpen}>{t("NewCompany")}</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="CompanyManagement__Modal">
            {FormNewCompany()}
            <Button onClick={handleNewCompany}>{t("Save")}</Button>
          </Box>
        </Modal>
        <Box sx={{ minWidth: 120 }}>
          <div className="APIKEY__filter">
            <FormControl fullWidth>
              <Autocomplete
                id={t("Emplacement")}
                options={
                  <em>None</em> &&
                  restaurants.map((resto) => resto.nomEmplacement)
                }
                onChange={(e) => handleChangeEmplacement(e)}
                sx={{ marginRight: "10px" }}
                value={restaurant ?? ""}
                renderInput={(params) => (
                  <TextField {...params} label={t("Location")} />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                id={t("Company")}
                options={
                  companies &&
                  companies.length > 0 &&
                  companies.map((c) => c.noAccpac + " - " + c.nom)
                }
                onChange={(e) => handleChangeCompany(e)}
                sx={{ marginRight: "10px" }}
                value={company ? company.noAccpac + " - " + company.nom : ""}
                renderInput={(params) => (
                  <TextField {...params} label={t("Company")} />
                )}
              />
            </FormControl>
          </div>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
          <FullWidthTabs
            company={company}
            TabCompany={TabCompany}
            TabShareHolder={TabShareHolders}
          />
        </div>
        <Modal
          open={openModalAdresse}
          onClose={handleCloseModalAdresse}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="CompanyManagement__Modal">
            <DetailsShareholder adresse={adresseShareholder} />
          </Box>
        </Modal>
        <CustomAlert
          open={showAlert}
          closeMessage={setShowAlert}
          typeMessage={typeMessage}
          message={message}
        />
      </div>
    </>
  );
}

export default CompanyManagement;

import React, { useEffect, useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Badge,
  Link,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "../../css/Article.css";

export default function Article({ article, unread, reload }) {
  const [attachments, setAttachments] = useState([]);
  const { t } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);
  const userLang = useSelector((state) => state.user.lang);

  useEffect(() => {
    fetchAttachments();
  }, []);

  const logReading = async () => {
    const data = {
      idObject: article.id,
      object: "Article",
      username: userState.username,
      event: "Read",
      descriptionEvent: `Reading of article ${article.id}`,
    };

    await fetch(`${process.env.REACT_APP_API_URL}/Logger/ReadArticle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    });
  };

  const fetchAttachments = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/GetAttachments/${article.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setAttachments(data);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  }, [article.id, userState]);

  const handleFileDownload = useCallback(
    async (fileName) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/article/DownloadAttachment/${fileName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    [userState]
  );

  const handleAccordionClick = (e) => {
    if (e.currentTarget.style.backgroundColor !== "lightgrey") {
      logReading();
      if (unread) reload(true);
    }
    e.currentTarget.style.backgroundColor =
      e.currentTarget.style.backgroundColor === "lightgrey"
        ? "white"
        : "lightgrey";
  };

  const renderArticleTitle = () => {
    return userLang === "FR" ? article.titleFr : article.titleEn;
  };

  const renderArticleText = () => {
    return userLang === "FR" ? article.textFr : article.textEn;
  };

  const renderAttachmentLinks = () =>
    attachments
      .filter((attachment) => attachment.lang === userLang)
      .map((attachment) => (
        <Link
          key={attachment.fileName}
          onClick={() => handleFileDownload(attachment.fileName)}
        >
          <ListItemText primary={attachment.fileName} />
        </Link>
      ));

  return (
    <div className="article__main">
      {renderArticleTitle() && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="TitleAccordion"
            onClick={handleAccordionClick}
          >
            {unread && (
              <Badge
                color="primary"
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 16,
                    backgroundColor: "red",
                  },
                }}
                className="article__main__Pastillebadge"
              />
            )}
            <Typography component="div">
              <h5>{Parser(renderArticleTitle())}</h5>
              <p className="article__main__detailArticle">
                {t("PublishedDate")} : {article.publicationDate}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{Parser(renderArticleText())}</Typography>
            {renderAttachmentLinks()}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

import React, { useEffect } from "react";

const url = "https://forms.office.com/r/syG1AkWkTa";

function InternalEmployeeSurvey() {
  useEffect(() => {
    window.location.href = url;
  }, []);

  return (
    <div className="App">
      <p>Redirecting to the survey...</p>
    </div>
  );
}

export default InternalEmployeeSurvey;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomAlert from "../Common/Components/CustomAlert";
import { useSelector } from "react-redux";

function CreateEmployee(props) {
  const { t } = useTranslation(["Employee"], ["Common"]);
  const [lastname, setLastname] = useState();
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [nodooliz, setNodooliz] = useState();
  const [language, setLanguage] = useState();
  const [department, setDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [languages, setLanguages] = useState(["FR", "EN"]);

  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const userState = useSelector((state) => state.user);

  const GenerateUsername = async () => {
    var userInfo = {
      Firstname: name,
      Lastname: lastname,
    };
    fetch(`${process.env.REACT_APP_API_URL}/employee/GenerateUsername`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(userInfo),
    })
      .then((results) => {
        return results.text();
      })
      .then((data) => {
        setUsername(data);
      });
  };

  const handleCreateEmployee = async () => {
    // Create the new employee object
    const newEmployee = {
      noEmplacement: props.noEmplacement,
      username: username,
      name: name,
      lastName: lastname,
      email: email,
      langue: language,
      noDooliz: nodooliz,
      department: department,
    };

    try {
      // Validate employee information
      if (validateEmployee() && uniqueEmail() && uniqueUsername()) {
        // Send POST request to create employee
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/employee/CreateEmployeeRestaurant`,
          {
            method: "POST",
            credentials: "include", // Send cookies if needed
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`, // Ensure token is valid
            },
            body: JSON.stringify(newEmployee), // Convert employee object to JSON
          }
        );

        // Check if the response was successful
        const data = await response.text();
        if (response.ok) {
          props.closeModal();
          setMessage("User created successfully");
          setTypeMessage("success");
          setShowAlert(true);
          window.location.reload(); // Reload the page if successful
        } else {
          throw new Error(data || "Error creating user");
        }
      }
    } catch (error) {
      console.error("Error creating employee:", error);
      setMessage(error.message || "An error occurred");
      setTypeMessage("error");
      setShowAlert(true);
    }
  };

  const GetExternalDepartment = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetExternalDepartment`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setDepartments(data.filter((d) => d.id !== 7));
      });
  };

  const validateEmployee = () => {
    if (!name || !email || !lastname || !language || !department) {
      setMessage(
        "Please complete at least the name, the lastname, the language, the email and the department for this new user"
      );
      setTypeMessage("echec");
      setShowAlert(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (name && lastname) {
      GenerateUsername();
    }
  }, [name, lastname]);

  useEffect(() => {
    GetExternalDepartment();
  }, []);

  const uniqueEmail = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/employee/UniqueEmail/${email}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data === false) {
          setMessage("email deja utilisee");
          setTypeMessage("echec");
          setShowAlert(true);
        } else {
          return true;
        }
      });
  };

  const uniqueUsername = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/employee/UniqueUsername/${username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data === false) {
          setMessage("username deja utilisee");
          setTypeMessage("echec");
          setShowAlert(true);
        } else {
          return true;
        }
      });
  };

  return (
    <>
      <h5>{t("CreateEmployeeHeader")}</h5>
      <Box sx={{ padding: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Lastname")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="lastname"
              name="lastname"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastname(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Firstname")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="name"
              name="name"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Username")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="Username"
              name="Username"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Email")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="Email"
              name="Email"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("NoDooliz")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="Nom"
              name="Nom"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={nodooliz}
              onChange={(e) => setNodooliz(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Language")}
            </InputLabel>
          </Grid>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("Language")}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languages.map((l, i) => (
              <MenuItem key={i} value={l}>
                {l}
              </MenuItem>
            ))}
          </Select>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 700,
              }}
            >
              {t("Department")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={department}
              label={t("Department")}
              onChange={(e) => setDepartment(e.target.value)}
            >
              {departments.map((d, i) => (
                <MenuItem key={i} value={d}>
                  {d.textFr}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Button onClick={handleCreateEmployee}>{t("Save")}</Button>
      </Box>
      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </>
  );
}

export default CreateEmployee;

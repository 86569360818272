import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import "./css/APIKey.css";

function APIKey() {
  const { t } = useTranslation(["Articles"]);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [currentNoEmplacement, setCurrentNoEmplacement] = useState();
  const [currentNoCompagnie, setNoCurrentCompany] = useState();
  const [dataAPI, setDataAPI] = useState();
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const userState = useSelector((state) => state.user);

  const GetRestaurants = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetRestaurants`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => results.json())
      .then((data) => setRestaurants(data));
  };

  const GetCompagnies = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/company/GetCompaniesByEmplacement/${currentNoEmplacement}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => results.json())
      .then((data) => setCompanies(data));
  };

  const GetIdKeyAPI = async (noCompany) => {
    fetch(`${process.env.REACT_APP_API_URL}/APIKey/GetIdKeyAPI/${noCompany}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => results.json())
      .then((data) => setDataAPI(data));
  };

  const handleChangeRestaurant = (event) => {
    setRestaurant(event.target.value);
    const selectedRestaurant = restaurants.find(
      (r) => r.nomEmplacement === event.target.value
    );
    setCurrentNoEmplacement(selectedRestaurant.noEmplacement);
  };

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
    const selectedCompany = companies.find((c) => c.nom === event.target.value);
    setNoCurrentCompany(selectedCompany.noCompagnie);
    GetIdKeyAPI(selectedCompany.noCompagnie);
  };

  useEffect(() => {
    GetRestaurants();
  }, []);

  useEffect(() => {
    if (currentNoEmplacement) GetCompagnies();
  }, [currentNoEmplacement]);

  useEffect(() => {
    if (dataAPI) {
      const newData = createData();
      setRows(newData);
    }
  }, [dataAPI]);

  const createData = () => {
    if (dataAPI) {
      const { idUser, generatedDate } = dataAPI;
      return [{ idUser, generatedDate }];
    }
    return [];
  };

  const handleGenerateKey = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/APIKey/GetNewAPIKey/${currentNoCompagnie}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => results.json())
      .then((data) => {
        setAlert(true);
        setAlertContent(data.api_keys);
      });
  };

  return (
    <div>
      <Grid container spacing={2} className="APIKEY__filter">
        <Grid item xs={12} sm={6}>
          <FormControl sx={{ width: "90%", margin: 2 }}>
            <InputLabel id="restaurant-label">{t("restaurant")}</InputLabel>
            <Select
              labelId="restaurant-label"
              id="restaurant-select"
              value={restaurant ?? ""}
              label={t("restaurant")}
              onChange={handleChangeRestaurant}
            >
              {restaurants.map((resto) => (
                <MenuItem
                  key={resto.noEmplacement}
                  value={resto.nomEmplacement}
                >
                  {resto.nomEmplacement}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl sx={{ width: "90%", margin: 2 }}>
            <InputLabel id="company-label">{t("company")}</InputLabel>
            <Select
              labelId="company-label"
              id="company-select"
              value={company ?? ""}
              label={t("Company")}
              onChange={handleChangeCompany}
            >
              {companies.map((compagnie) => (
                <MenuItem key={compagnie.noCompagnie} value={compagnie.nom}>
                  {compagnie.nom +
                    " | " +
                    compagnie.noAccpac +
                    " | " +
                    compagnie.dateOuverture +
                    " | " +
                    compagnie.dateFermeture}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className="APIKey__DataTable" style={{ marginTop: "20px" }}>
        <TableContainer component={Paper} sx={{ width: "90%", margin: 2 }}>
          <Table
            sx={{
              minWidth: 650,
              "@media (max-width: 600px)": { minWidth: 0, width: "100%" },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "lightgrey" }}>
                <TableCell>ID Key</TableCell>
                <TableCell align="right">Generated date</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                rows.map((row) => (
                  <TableRow key={row.idUser}>
                    <TableCell component="th" scope="row">
                      {row.idUser}
                    </TableCell>
                    <TableCell align="right">{row.generatedDate}</TableCell>
                    <TableCell align="right">
                      <Button onClick={handleGenerateKey}>Generate Key</Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {alert && (
          <Alert
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(alertContent);
                }}
              >
                Copy
              </Button>
            }
          >
            New key generated successfully! Key: {alertContent}
          </Alert>
        )}
      </div>
    </div>
  );
}

export default APIKey;

import React from "react";
import TextField from "@mui/material/TextField";
import { useTranslation, Trans } from "react-i18next";

import "./css/StepEmailRecuperation.css";

export function StepEmailRecuperation(props) {
  const { t, i18n } = useTranslation(["MFA"]);

  return (
    <div>
      <Trans>
        <div className="StepEmailRecuperation__title">{props.description}</div>
      </Trans>
      <TextField
        style={{ width: "300px", margin: "5px" }}
        type="text"
        label={props.label}
        variant="outlined"
        inputRef={props.valueRef}
        onChange={props.validateData}
      />
    </div>
  );
}

export default StepEmailRecuperation;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

function TabRestaurant(props) {
  const [siegeNumber, setSiegeNumber] = useState();
  const [surface, setSurface] = useState();
  const [contact, setContact] = useState();
  const [ip, setIp] = useState();
  const [deliveryCost, setDeliveryCost] = useState();
  const [deliveryCostByPallet, setDeliveryCostByPallet] = useState();
  const { t, i18n } = useTranslation(["Articles"]);
  const [typePaiement, setTypePaiement] = useState();
  const [typePaiements, setTypePaiements] = useState([]);

  const userState = useSelector((state) => state.user);

  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    GetTypePaiement();
  }, []);

  useEffect(() => {
    console.log(props.emplacement);
    if (props.emplacement) {
      setIp(props.emplacement.ipAddress);
      setDeliveryCost(props.emplacement.prixLivraison);
      setDeliveryCostByPallet(props.emplacement.prixLivraisonParPalette);
      setTypePaiement(props.emplacement.nomTypePaiementFr);
      setSiegeNumber(props.emplacement.nbSiege);
      setSurface(props.emplacement.superficie);
    }
  }, []);

  const GetTypePaiement = async () => {
    fetch("emplacement/GetTypePaiement", {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setTypePaiements(data);
      });
  };

  const handleSaveRestaurant = async () => {
    var dataRestaurant = {
      noEmplacement: props.emplacement.noEmplacement,
      siegeNumber: siegeNumber,
      surface: surface,
      ip: ip,
      deliveryCost: deliveryCost,
      deliveryCostByPallet: deliveryCostByPallet,
      username: userState.username,
      tab: "Restaurant",
    };

    fetch(`emplacement/SaveLocation`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataRestaurant),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("location saved");
          setShowAlert(true);
        }
        if (data == "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, location not saved");
          setShowAlert(true);
        }
      });
  };

  return (
    <>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("openDate")}
                    id="Date ouverture"
                    name="Date ouverture"
                    fullWidth
                    size="medium"
                    autoComplete="off"
                    variant="outlined"
                    value={
                      props.emplacement
                        ? dayjs(props.emplacement.dateOuverture)
                        : ""
                    }
                    readOnly={true}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("closureDate")}
                    id="Mot de passe DC"
                    name="Mot de passe DC"
                    fullWidth
                    size="medium"
                    autoComplete="off"
                    variant="outlined"
                    value={
                      props.emplacement
                        ? dayjs(props.emplacement.dateFermeture)
                        : ""
                    }
                    readOnly={true}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="NombreSieges"
                label={t("seatsNumber")}
                name="NombreSieges"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={siegeNumber || ""}
                onChange={(e) => setSiegeNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Superficie"
                name="Superficie"
                label={t("Surface")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={surface || ""}
                onChange={(e) => setSurface(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="TypePaiement">Type paiement</InputLabel>
                <Select
                  labelId="TypePaiement"
                  label={t("PaymentType")}
                  id="TypePaiement"
                  value={typePaiement || ""}
                  defaultValue={typePaiement || ""}
                  onChange={(e) => setTypePaiement(e.target.value)}
                >
                  {typePaiements.map((t, i) => (
                    <MenuItem key={i} value={t.nomFr}>
                      {t.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="IPCaisse"
                label={t("IPCaisse")}
                name="IPCaisse"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={ip || ""}
                onChange={(e) => setIp(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="CoutFixeLivraison"
                name="CoutFixeLivraison"
                label={t("DeliveryCost")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={deliveryCost || ""}
                onChange={(e) => setDeliveryCost(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="CoutLivraison"
                label={t("DeliveryCostByPallets")}
                name="CoutLivraison"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={deliveryCostByPallet || ""}
                onChange={(e) => setDeliveryCostByPallet(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSaveRestaurant}>
                Save details
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </>
  );
}

export default TabRestaurant;

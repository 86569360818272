import React, { useEffect, useState } from "react";
import Category from "./Category";
import { useSelector } from "react-redux";
import { Modal, Box, Typography } from "@mui/material";
import Article from "../Article";
import SearchArticles from "../SearchArticles/SearchArticles";

import "./css/ArticleView.css";

// IMPORT LOGO CATEGORY
import imageMarketing from "../../../resources/logoCategory/ICONE_extranet_Marketing.jpg";
import imageCasserole from "../../../resources/logoCategory/ICONE_extranet_Casseroles.jpg";
import imageFinanceEN from "../../../resources/logoCategory/ICONE_extranet_Finance_legal_EN.jpg";
import imageInformatiqueEN from "../../../resources/logoCategory/ICONE_extranet_OperationSystem_IT_EN.jpg";
import imageRHEN from "../../../resources/logoCategory/ICONE_extranet_humanresources_Misc_EN.jpg";
import imageDeveloppementEN from "../../../resources/logoCategory/ICONE_extranet_Development_EN.jpg";
import imageRDEN from "../../../resources/logoCategory/ICONE_extranet_RD_Procurement_EN.jpg";
import imageFormationEN from "../../../resources/logoCategory/ICONE_extranet_Operation_training_EN.jpg";
import imageFinanceFR from "../../../resources/logoCategory/ICONE_extranet_Finance_Legal.jpg";
import imageInformatiqueFR from "../../../resources/logoCategory/ICONE_extranet_Systeme Explotation_TI_FR.jpg";
import imageRHFR from "../../../resources/logoCategory/ICONE_extranet_RessHumaine_FR.jpg";
import imageDeveloppementFR from "../../../resources/logoCategory/ICONE_extranet_developpement_FR.jpg";
import imageRDFR from "../../../resources/logoCategory/ICONE_extranet_RD_Achat_FR.jpg";
import imageFormationFR from "../../../resources/logoCategory/ICONE_extranet_Operation_formation_FR.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ArticleView() {
  const [articles, setArticles] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [open, setOpen] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState({
    RH: 0,
    RD: 0,
    Casserole: 0,
    Finance: 0,
    Formation: 0,
    Informatique: 0,
    Developpement: 0,
    Marketing: 0,
  });
  const [unreadArticles, setUnreadArticles] = useState([]);
  const [reloadPublishArticle, setReloadPublishArticle] = useState(false);
  const userState = useSelector((state) => state.user);

  const categoryImages = {
    FR: {
      RH: imageRHFR,
      RD: imageRDFR,
      Casserole: imageCasserole,
      Finance: imageFinanceFR,
      Formation: imageFormationFR,
      Informatique: imageInformatiqueFR,
      Developpement: imageDeveloppementFR,
      Marketing: imageMarketing,
    },
    EN: {
      RH: imageRHEN,
      RD: imageRDEN,
      Casserole: imageCasserole,
      Finance: imageFinanceEN,
      Formation: imageFormationEN,
      Informatique: imageInformatiqueEN,
      Developpement: imageDeveloppementEN,
      Marketing: imageMarketing,
    },
  };

  const handleOpen = (e) => {
    setOpen(true);
    setCurrentCategory(e.currentTarget.getAttribute("value"));
  };

  const handleClose = () => {
    getUnreadArticle();
    setOpen(false);
  };

  const fetchPublishedArticles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/GetPublishedArticles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      setArticles(data);
      setReloadPublishArticle(false);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const getUnreadArticle = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/logger/LogArticle/${userState.username}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      const unreadArticles = await response.json();
      setUnreadArticles(unreadArticles);
      const newCounts = {
        RH: 0,
        RD: 0,
        Casserole: 0,
        Finance: 0,
        Formation: 0,
        Informatique: 0,
        Developpement: 0,
        Marketing: 0,
      };

      articles.forEach((article) => {
        console.log(article);
        if (!unreadArticles.some((unread) => article.id === unread.idObject)) {
          switch (article.idCategory) {
            case 1:
              newCounts.RH++;
              break;
            case 2:
              newCounts.RD++;
              break;
            case 3:
              newCounts.Casserole++;
              break;
            case 4:
              newCounts.Finance++;
              break;
            case 5:
              newCounts.Formation++;
              break;
            case 6:
              newCounts.Informatique++;
              break;
            case 7:
              newCounts.Developpement++;
              break;
            case 8:
              newCounts.Marketing++;
              break;
            default:
              break;
          }
        }
      });

      setUnreadCounts(newCounts);
    } catch (error) {
      console.error("Error fetching unread articles:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchPublishedArticles();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getUnreadArticle();
    };
    fetchData();
  }, [articles]);

  useEffect(() => {
    if (reloadPublishArticle) {
      fetchPublishedArticles();
      getUnreadArticle();
    }
  }, [reloadPublishArticle, unreadCounts]);

  const displayCategory = (idCategory, imageName, unread) => {
    return articles.filter((a) => a.idCategory === idCategory).length <= 0 ? (
      <Category name={imageName} unread={unread} blackAndWhite={true} />
    ) : (
      <Category
        name={imageName}
        onClick={handleOpen}
        idCategory={idCategory}
        unread={unread}
        blackAndWhite={false}
      />
    );
  };

  const CategoryByLanguage = () => {
    const images = categoryImages[userState.lang || "EN"];
    return (
      <>
        {displayCategory(1, images.RH, unreadCounts.RH)}
        {displayCategory(2, images.RD, unreadCounts.RD)}
        {displayCategory(3, images.Casserole, unreadCounts.Casserole)}
        {displayCategory(4, images.Finance, unreadCounts.Finance)}
        {displayCategory(5, images.Formation, unreadCounts.Formation)}
        {displayCategory(6, images.Informatique, unreadCounts.Informatique)}
        {displayCategory(7, images.Developpement, unreadCounts.Developpement)}
        {displayCategory(8, images.Marketing, unreadCounts.Marketing)}
      </>
    );
  };

  return (
    <>
      <div className="MemoHebdo__Filter">{<SearchArticles />}</div>
      <div className="MemoHebdo__Categories"></div>
      <div className="MemoHebdo__ModalArea">
        <div className="MemoHebdo__GridCategory">{CategoryByLanguage()}</div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="MemoHebdo__Modal">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {articles
              .filter((a) => a.idCategory == currentCategory)
              .map((article) =>
                unreadArticles.some((ua) => ua.idObject === article.id) ? (
                  <Article
                    article={article}
                    unread={false}
                    reload={setReloadPublishArticle}
                  />
                ) : (
                  <Article
                    article={article}
                    unread={true}
                    reload={setReloadPublishArticle}
                  />
                )
              )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default ArticleView;

import React, { useEffect, useState } from "react";

import "./css/RecipesCategory.css";

function RecipesCategory({ name, image, onClick, selectedCategory }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      selectedCategory &&
      (selectedCategory.textEn === name || selectedCategory.textFr === name)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  });

  const handleClick = (e) => {
    setIsActive(!isActive);
    onClick(e); // Pass the clicked recipe to the onClick handler
  };
  return (
    <div className={`RecipesCategory ${isActive ? "active" : ""}`} data={name} onClick={(e) => handleClick(e)} >
      <img src={image} alt={name} />
      <h6>{name}</h6>
    </div>
  );
}

export default RecipesCategory;

import React, { useRef, useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "./css/MFA.css";
import store from "../../../redux/store";
import { login, setLang, setToken } from "../../../redux/actions";
import { useSelector } from "react-redux";
import CustomAlert from "../../Common/Components/CustomAlert";

export function MFA(props) {
  const [value, setValue] = useState("email");
  const [secretCode, setSecretCode] = useState();
  const [inputValue, setInputValue] = useState(""); // Track the input value
  const valueRef = useRef("");
  const [isSent, setIsSent] = useState(false);
  const { t, i18n } = useTranslation(["MFA"]);

  // State for alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error"); // Can be "error" or "success"

  const userState = useSelector((state) => state.user);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleValidateCode = () => {
    if (inputValue.trim() === secretCode?.trim() && isSent) {
      // If the code matches, proceed with validation
      localStorage.setItem("username", props.userInfo.userName);
      localStorage.setItem("lang", props.userInfo.langue);
      localStorage.setItem("token", props.token);

      store.dispatch(setToken(props.token));
      store.dispatch(
        login({ username: props.userInfo.userName, idSession: props.idSession })
      );
      store.dispatch(setLang(props.userInfo.langue));
      window.location.reload();
    } else {
      // If the code does not match, show error alert
      setAlertMessage("Incorrect code, please try again.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleSend = async () => {
    var sendMethod = "email";
    var data = props.userInfo.email;
    if (value === "phone") {
      sendMethod = "SMS";
      data = props.userInfo.noTelephoneCellulaire;
    }
    fetch(`${process.env.REACT_APP_API_URL}/login/code${sendMethod}/${data}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((results) => results.text())
      .then((data) => {
        setSecretCode(data);
        setIsSent(true);

        // Show alert for code sent
        setAlertMessage("Code sent successfully!");
        setAlertType("success");
        setAlertOpen(true);
      });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Check if the entered code matches the secretCode and validate automatically
  useEffect(() => {
    if (inputValue.trim() === secretCode?.trim() && isSent) {
      handleValidateCode();
    }
  }, [inputValue, secretCode, isSent]); // Run effect when inputValue or secretCode changes

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">
        {t("WhereToSendCode")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="email"
          control={<Radio />}
          label={props.userInfo.email}
        />
        {props.userInfo.noTelephoneCellulaire && (
          <FormControlLabel
            value="phone"
            control={<Radio />}
            label={props.userInfo.noTelephoneCellulaire}
          />
        )}
      </RadioGroup>
      <Button onClick={handleSend}>Send</Button>
      {isSent && (
        <>
          <TextField
            margin="normal"
            fullWidth
            name="Code"
            label="Code"
            type="text"
            id="Code"
            inputRef={valueRef}
            value={inputValue} // Controlled input
            onChange={handleInputChange} // Update inputValue on change
          />
          <Button onClick={handleValidateCode}>{t("Validate")}</Button>
        </>
      )}

      {/* Custom Alert Component */}
      <CustomAlert
        open={alertOpen}
        closeMessage={setAlertOpen} // Close the alert when needed
        message={alertMessage}
        typeMessage={alertType}
      />
    </FormControl>
  );
}

export default MFA;

import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import TextField from "@mui/material/TextField";
import "react-quill/dist/quill.snow.css";

function Editor({
  title,
  setTitle,
  setText,
  text,
  readOnly,
  onKeyPressed,
  toolbarId,
}) {
  return (
    <>
      {title && (
        <TextField
          margin="normal"
          fullWidth
          id="outlined-titleFr"
          label="Titre"
          name="titleFr"
          disabled={readOnly}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          onKeyUp={onKeyPressed}
        />
      )}

      <EditorToolbar handleSave={onKeyPressed} toolbarId={toolbarId} />
      <ReactQuill
        theme="snow"
        value={text}
        onChange={setText}
        placeholder={"article en francais..."}
        modules={modules(toolbarId)}
        formats={formats}
        readOnly={readOnly}
        onKeyUp={onKeyPressed}
      />
    </>
  );
}

export default Editor;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import CustomAlert from "../Common/Components/CustomAlert";

function TabEmplacement(props) {
  const [nameLocation, setNameLocation] = useState();

  const [emplacement, setEmplacement] = useState();

  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [province, setProvince] = useState();
  const [country, setCountry] = useState();
  const [adress, setAdress] = useState();
  const [region, setRegion] = useState();
  const { t, i18n } = useTranslation(["Location"], ["Common"]);
  const [company, setCompany] = useState();
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState();
  const [fax, setFax] = useState();
  const [email, setEmail] = useState();

  const userState = useSelector((state) => state.user);

  const [showAlert, setShowAlert] = useState(false);

  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    GetCountries();
  }, []);

  useEffect(() => {
    if (country) {
      GetProvinces();
    }
  }, [country]);

  useEffect(() => {
    if (province) {
      GetRegions();
    }
  }, [province]);

  useEffect(() => {
    if (props.emplacement) {
      setNameLocation(props.emplacement.nom);
      setAdress(props.emplacement.adresseEmplacement.adresse);
      setPhoneNumber(props.emplacement.noTelPrincipal);
      setFax(props.emplacement.noTelFax);
      setEmail(props.emplacement.email);

      setCity(props.emplacement.adresseEmplacement.ville);

      setZipCode(props.emplacement.adresseEmplacement.codePostal);

      setCountry(
        countries.find(
          (c) => c.nomFr == props.emplacement.adresseEmplacement.nomPaysFr
        )
      );

      setProvince(
        provinces.find(
          (p) => p.nomFr == props.emplacement.adresseEmplacement.nomProvinceFr
        )
      );

      setRegion(
        regions.find(
          (r) => r.nomFr == props.emplacement.adresseEmplacement.nomRegionFr
        )
      );
    }
  }, [props.emplacement]);

  const GetCountries = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetCountries`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let paysFr = [];
        let paysEn = [];

        data.forEach((element) => {
          paysFr.push(element.paysFr);
          paysEn.push(element.paysEn);
        });

        var uniquePaysFR = paysFr.filter((x, i) => paysFr.indexOf(x) === i);
        var uniquePaysEN = paysEn.filter((x, i) => paysEn.indexOf(x) === i);
        setCountries(data);
        setCountry(
          data.find(
            (c) => c.nomFr == props.emplacement.adresseEmplacement.nomPaysFr
          )
        );
      });
  };

  const GetProvinces = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetProvinceByCountry/${country.noPays}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let ProvinceFr = [];
        let ProvinceEn = [];

        data.forEach((element) => {
          ProvinceFr.push(element.provinceFr);
          ProvinceEn.push(element.provinceEn);
        });

        var uniqueProvinceEN = ProvinceEn.filter(
          (x, i) => ProvinceEn.indexOf(x) === i
        );
        var uniqueProvinceFR = ProvinceFr.filter(
          (x, i) => ProvinceFr.indexOf(x) === i
        );

        setProvinces(data);

        setProvince(
          data.find(
            (p) => p.nomFr == props.emplacement.adresseEmplacement.nomProvinceFr
          )
        );
      });
  };

  const GetRegions = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRegionsByProvinces/${province.noProvince}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let RegionFr = [];
        let RegionEn = [];

        data.forEach((element) => {
          RegionFr.push(element.regionFr);
          RegionEn.push(element.regionEn);
        });

        var uniqueRegionEN = RegionEn.filter(
          (x, i) => RegionEn.indexOf(x) === i
        );
        var uniqueRegionFR = RegionFr.filter(
          (x, i) => RegionFr.indexOf(x) === i
        );

        setRegions(data);
        setRegion(
          data.find(
            (r) => r.nomFr == props.emplacement.adresseEmplacement.nomRegionFr
          )
        );
      });
  };

  const handleSaveEmplacement = async () => {
    var dataLocation = {
      noEmplacement: props.emplacement.noEmplacement,
      nameLocation: nameLocation,
      adress: adress,
      phoneNumber: phoneNumber,
      fax: fax,
      email: email,
      zipCode: zipCode,
      country: country,
      region: region,
      province: province,
      city: city,
      username: userState.username,
      tab: "Location",
    };

    fetch(`${process.env.REACT_APP_API_URL}/Location/SaveLocation`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataLocation),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data == "succes") {
          setTypeMessage("succes");
          setMessage("location saved");
          setShowAlert(true);
        }
        if (data == "echec") {
          setTypeMessage("echec");
          setMessage("There is an error, location not saved");
          setShowAlert(true);
        }
      });
  };

  return (
    <div>
      <Paper elevation={3} sx={{ marginRight: "5%", marginLeft: "5%" }}>
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nom"
                label={t("Lastname")}
                name="Nom"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={nameLocation || ""}
                onChange={(e) => setNameLocation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="NoTelephonePrincipal"
                label={t("PhoneNumber")}
                name="No Telephone Principal"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={phoneNumber || ""}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Courriel"
                name="Courriel"
                label={t("Email")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        elevation={3}
        sx={{ marginRight: "5%", marginLeft: "5%", marginTop: "2%" }}
      >
        <Box sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Adresse"
                label={t("Adress")}
                name="Adresse"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={adress || ""}
                onChange={(e) => setAdress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Ville"
                label={t("City")}
                name="Ville"
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={city || ""}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Pays">{t("Country")}</InputLabel>
                <Select
                  key={emplacement}
                  labelId="Pays"
                  id="Pays"
                  label={t("Country")}
                  value={country || ""}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Province">{t("Province")}</InputLabel>
                <Select
                  key={emplacement}
                  labelId="Province"
                  id="Province"
                  label={t("Province")}
                  value={province || ""}
                  onChange={(e) => setProvince(e.target.value)}
                >
                  {provinces.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="Region">{t("Region")}</InputLabel>
                <Select
                  key={emplacement}
                  labelId="Region"
                  id="Region"
                  label={t("Region")}
                  value={region || ""}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  {regions.map((r, i) => (
                    <MenuItem key={i} value={r}>
                      {r.nomFr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="codePostal"
                name="codePostal"
                label={t("Zipcode")}
                fullWidth
                size="medium"
                autoComplete="off"
                variant="outlined"
                value={zipCode || ""}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSaveEmplacement}>
                {t("GeneralSave")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <CustomAlert
        open={showAlert}
        closeMessage={setShowAlert}
        typeMessage={typeMessage}
        message={message}
      />
    </div>
  );
}

export default TabEmplacement;

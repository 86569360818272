import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "./CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import RecipesCategory from "./RecipesCategory";

import "./css/RecipesView.css";

// IMPORT LOGO CATEGORY
import icon_Benedictine from "../../resources/RecipesIcons/icon_Benedictine.png";
import icon_Beverages from "../../resources/RecipesIcons/icon_Beverages.png";
import icon_Eggs from "../../resources/RecipesIcons/icon_Eggs.png";
import icon_FrenchToast from "../../resources/RecipesIcons/icon_FrenchToast.png";
import icon_FruitPrep from "../../resources/RecipesIcons/icon_FruitPrep.png";
import icon_KitchenPrep from "../../resources/RecipesIcons/icon_KitchenPrep.png";
import icon_Omelette from "../../resources/RecipesIcons/icon_Omelette.png";
import icon_Pancakes from "../../resources/RecipesIcons/icon_Pancakes.png";
import icon_Sandwich from "../../resources/RecipesIcons/icon_Sandwich.png";
import icon_Alcohol from "../../resources/RecipesIcons/icon_Alcohol.png";
import icon_Waffles from "../../resources/RecipesIcons/icon_Waffles.png";
import icon_Archives from "../../resources/RecipesIcons/icon_Archives.png";
import icon_Crepomelette from "../../resources/RecipesIcons/icon_Crepomelette.png";
import icon_SchoolProgram from "../../resources/RecipesIcons/icon_SchoolProgram.png";
import icon_Events from "../../resources/RecipesIcons/icon_Events.png";
import icon_Extras from "../../resources/RecipesIcons/icon_Extras.png";
import icon_EarlyBird from "../../resources/RecipesIcons/icon_EarlyBird.png";
import icon_FruitCrepe from "../../resources/RecipesIcons/icon_FruitCrepe.png";
import icon_Skillet from "../../resources/RecipesIcons/icon_Skillet.png";
import icon_Group from "../../resources/RecipesIcons/icon_Group.png";
import icon_Other from "../../resources/RecipesIcons/icon_Other.png";
import icon_FreshFruits from "../../resources/RecipesIcons/icon_FreshFruits.png";
import icon_SavouryCrepe from "../../resources/RecipesIcons/icon_SavouryCrepe.png";
import icon_Lunch from "../../resources/RecipesIcons/icon_Lunch.png";
import icon_Kids from "../../resources/RecipesIcons/icon_Kids.png";
import icon_Teen from "../../resources/RecipesIcons/icon_Teen.png";
import icon_SweetnSalty from "../../resources/RecipesIcons/icon_SweetnSalty.png";

import RecipesList from "./RecipesList";
import Recipe from "./Recipe";
import { useSelector } from "react-redux";
import { Typography, CircularProgress } from "@mui/material";

const getCategoryImage = (categoryTextEn) => {
  switch (categoryTextEn) {
    case "Ben & Dictine":
      return icon_Benedictine;
    case "Beverages":
      return icon_Beverages;
    case "Eggs":
      return icon_Eggs;
    case "French Toast":
      return icon_FrenchToast;
    case "Fruit Prep":
      return icon_FruitPrep;
    case "Kitchen Prep":
      return icon_KitchenPrep;
    case "Omelettes":
      return icon_Omelette;
    case "Pancakes":
      return icon_Pancakes;
    case "Sandwich":
      return icon_Sandwich;
    case "Waffles":
      return icon_Waffles;
    case "Alcohol":
      return icon_Alcohol;
    case "Archives":
      return icon_Archives;
    case "Crêpomelettes":
      return icon_Crepomelette;
    case "School program":
      return icon_SchoolProgram;
    case "Event":
      return icon_Events;
    case "BB Extras":
      return icon_Extras;
    case "Early-Bird":
      return icon_EarlyBird;
    case "Fruit crêpes":
      return icon_FruitCrepe;
    case "Skillets":
      return icon_Skillet;
    case "Group":
      return icon_Group;
    case "Other":
      return icon_Other;
    case "Fresh fruits":
      return icon_FreshFruits;
    case "Savoury crêpes":
      return icon_SavouryCrepe;
    case "Lunch Menu":
      return icon_Lunch;
    case "Kid's Menu":
      return icon_Kids;
    case "Teen Menu":
      return icon_Teen;
    case "Sweet'n salty":
      return icon_SweetnSalty;
    case "Optional Product":
      return icon_Other;
    default:
      return null; // Fallback in case no image is matched
  }
};

function RecipesView() {
  const { t, i18n } = useTranslation(["Recipes"]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRecipe, setSelectedrecipe] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesWithImages, setCategoriesWithImages] = useState([]);
  const [imagesRecipe, setImagesRecipe] = useState();
  const [videosRecipe, setVideosRecipe] = useState();
  const [recipeLoading, setRecipeLoading] = useState(true); // Loading state
  const [loading, setLoading] = useState(false); // Loading state

  const userState = useSelector((state) => state.user);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetRecipeCategories`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch categories");
      const data = await response.json();
      // Map categories with images
      setCategoriesWithImages(
        data.map((category) => ({
          ...category,
          image: getCategoryImage(category.textEn), // Add image
        }))
      );
      setCategories(data);
    } catch (error) {
      console.error("Error fetching Recipes:", error);
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  const fetchRecipe = async () => {
    if (!selectedRecipe) return; // Guard clause
    setRecipeLoading(true);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Recipe/GetRecipeById/${selectedRecipe.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch recipe");
      const data = await response.json();
      setRecipe(data);
      setRecipeLoading(false);
    } catch (error) {
      console.error("Error fetching Recipe:", error);
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  // Function to handle category selection
  const handleSelectCategory = (e) => {
    const selectedCategoryName = e.currentTarget.getAttribute('data');
    const selectedCategory = categoriesWithImages.find(
      (category) => userState.lang === "FR" ? category.textFr === selectedCategoryName : category.textEn === selectedCategoryName
    );
    setSelectedCategory(selectedCategory); // Set the selected category object
    setSelectedrecipe(null);
    setRecipe(null);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional for smooth scrolling
    });
    fetchRecipe();
  }, [selectedRecipe, selectedCategory]);

  return (
    <div className="RecipesView_Container">
      <div className="RecipesHeader">
        <h3>{t("Recipes")}</h3>
        <CustomBreadcrumbs
          category={selectedCategory}
          recipe={selectedRecipe}
          setRecipe={setRecipe}
          setSelectedRecipe={setSelectedrecipe}
          setCategory={setSelectedCategory}
        />
      </div>
      {/* Conditionally render RecipesList if a category is selected */}

      {recipe && (
        <div className="RecipeView__Recipe">
          {recipeLoading && (
              <div className={"RecipeLoading"}>
                <CircularProgress
                  className="RecipeViewLoadingSpinner"
                  size="5rem"
                />
              </div>
          )}
          <Recipe
            key={recipe.recette.id}
            recipe={recipe && recipe.recette}
            images={recipe && recipe.images}
            videos={
              recipe && userState.lang === "FR"
                ? recipe.videoFr
                : recipe.videoEn
            }
          />
        </div>
      )}
      {selectedCategory && (
        <div className="RecipeView__Recipes">
          <div className="TriangleDecoration" />
          <div className="RecipeView__ImageCategory">
            <img src={selectedCategory.image} alt={selectedCategory.name} />
            <Typography variant="h6">{userState.lang === "FR" ? selectedCategory.textFr : selectedCategory.textEn}</Typography>
          </div>
          <RecipesList
            category={selectedCategory}
            setRecipe={setSelectedrecipe}
            selectedRecipe={selectedRecipe}
            setImagesRecipe={setImagesRecipe}
          />
        </div>
      )}
      <div className="RecipesView__GridCategory">
        <div className="TriangleDecoration" />

        {categoriesWithImages.map((category) => (
          <RecipesCategory
            name={userState.lang === "FR" ? category.textFr : category.textEn}
            image={category.image}
            selectedCategory={selectedCategory}
            onClick={handleSelectCategory}
          />
        ))}
      </div>
      <div className="RecipesView__Footer" />
    </div>
  );
}

export default RecipesView;

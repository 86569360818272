import React, { useEffect, useState } from "react";
import Editor from "../../Common/Components/Editor/Editor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";

import "./css/SpecialMessageEditor.css";

function SpecialMessageEditor() {
  const [textFr, setTextFr] = useState(null);
  const [textEn, setTextEn] = useState(null);
  const [publicationDate, setPublicationDate] = useState("");
  const [endPublicationDate, setEndPublicationDate] = useState("");

  const userState = useSelector((state) => state.user);
  const { t } = useTranslation(["Articles"]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/specialMessage/GetMessage`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setTextEn(data.textEn);
        setTextFr(data.textFr);
        setEndPublicationDate(data.endPublicationDate);
        setPublicationDate(data.publicationDate);
      });
  }, []);

  const handleClearDate = () => {
    setEndPublicationDate(null);
    setPublicationDate(null);
  };

  const handleSaveMessage = async () => {
    if (publicationDate && endPublicationDate) {
      var messageData = {
        TextFR: textFr,
        TextEN: textEn,
        Author: userState.username,
        publishedDate: publicationDate,
        endPublishedDate: endPublicationDate,
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/specialMessage/SaveMessageCasserole`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(messageData),
        }
      ).then((results) => {
        return results.json();
      });
    } else {
      alert("Dates mandatories ! ");
    }
  };

  const handleSaveWhenKeyPressed = async (event) => {};

  return (
    <div>
      <div className="EditorSpecialMessage__date__datePicker">
        <div className="EditorSpecialMessage__date__datePicker__publicationDate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>{t("PublicationDate")}</label>
            <DatePicker
              defaultValue={dayjs(publicationDate)}
              value={dayjs(publicationDate)}
              onChange={(newValue) => setPublicationDate(newValue)}
            />
          </LocalizationProvider>
        </div>
        <div className="EditorSpecialMessage__date__datePicker__endPublishedDate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>{t("EndPublishedDate")}</label>
            <DatePicker
              defaultValue={dayjs(endPublicationDate)}
              value={dayjs(endPublicationDate)}
              onChange={(newValue) => setEndPublicationDate(newValue)}
            />
          </LocalizationProvider>
        </div>
        <Button variant="contained" onClick={handleClearDate}>
          {t("ClearDate")}
        </Button>
      </div>
      <div className="EditorSpecialMessage__EditorFr">
        <Editor
          title={null}
          setTitle={null}
          setText={setTextFr}
          text={textFr}
          readOnly={false}
          onKeyPressed={handleSaveWhenKeyPressed}
          toolbarId={"toolbarSpecialMessageFr"}
        />
      </div>
      <div className="EditorSpecialMessage__EditorEn">
        <Editor
          title={null}
          setTitle={null}
          setText={setTextEn}
          text={textEn}
          readOnly={false}
          onKeyPressed={handleSaveWhenKeyPressed}
          toolbarId={"toolbarSpecialMessageEn"}
        />
      </div>
      <Button onClick={handleSaveMessage} variant="contained">
        {t("Save")}
      </Button>
    </div>
  );
}

export default SpecialMessageEditor;

import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function ArticleLeftBar({ reloadArticle, setReload, selectArticle }) {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [articleInEdition, setArticleInEdition] = useState([]);
  const [publishedArticle, setPublishedArticle] = useState([]);
  const [publishArticle, setPublishArticle] = useState([]);
  const { t, i18n } = useTranslation(["Articles"]);
  const userState = useSelector((state) => state.user);

  const yearList = [2020, 2021, 2022, 2023];

  const fetchArticles = useCallback(
    async (endpoint, setState) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/article/${endpoint}`,
          {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        setState(data);
      } catch (error) {
        console.error(`Failed to fetch ${endpoint}`, error);
      }
    },
    [userState.token]
  );

  useEffect(() => {
    fetchArticles("GetPublishedArticles", setPublishArticle);
    fetchArticles("GetArticlesInEdition", setArticleInEdition);
    setReload(false);
  }, [reloadArticle, fetchArticles, setReload]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const renderArticleLabel = (article) => {
    const lang = i18n.language;
    const title = lang === "FR" ? article.titleFr : article.titleEn;
    return article.idEmployeLock ? (
      <Tooltip title={`Lock by ${article.employeLock}`} placement="right" arrow>
        <span>
          {title} <LockIcon />
        </span>
      </Tooltip>
    ) : (
      <span>{title}</span>
    );
  };

  const renderTreeItems = (articles, label, nodeId) => (
    <TreeItem nodeId={nodeId} label={label}>
      {articles.map((article) => (
        <TreeItem
          key={article.id}
          nodeId={article.id.toString()}
          label={renderArticleLabel(article)}
          onClick={() => selectArticle(article)}
        />
      ))}
    </TreeItem>
  );

  const renderTreeItemByYear = (year) => {
    return (
      <TreeItem nodeId={year.toString()} label={year}>
        {publishedArticle
          .filter(
            (a) =>
              new Date(a.publishedDate).getFullYear() >= year &&
              new Date(a.endPublishedDate).getFullYear() < year + 1
          )
          .map((article) => (
            <TreeItem
              key={article.id}
              nodeId={article.id.toString()}
              label={renderArticleLabel(article)}
              onClick={() => selectArticle(article)}
            />
          ))}
      </TreeItem>
    );
  };

  return (
    <div className="EdditionInProgress__Main">
      <div className="EdditionInProgress__Title">
        <h3>{t("Articles")}</h3>
      </div>
      <div className="EdditionInProgress__Treeview">
        <Box
          sx={{ height: 270, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        >
          <TreeView
            aria-label="controlled"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect
          >
            {renderTreeItems(articleInEdition, t("InEddition"), "24500")}
            {renderTreeItems(publishArticle, t("Published"), "26000")}
            <TreeItem nodeId="40000" label={t("AlreadyPublished")}>
              {yearList.map((year) => renderTreeItemByYear(year))}
            </TreeItem>
          </TreeView>
        </Box>
      </div>
    </div>
  );
}

export default ArticleLeftBar;

import React from "react";
import Badge from "@mui/material/Badge";

import "./css/Category.css";

export function Category({ onClick, name, idCategory, unread, blackAndWhite }) {
  return (
    <div className="Category__main">
      {blackAndWhite ? (
        <img
          src={name}
          onClick={onClick}
          value={idCategory}
          style={{ filter: "grayscale(100%)" }}
          alt=""
        />
      ) : (
        <img src={name} onClick={onClick} value={idCategory} alt="" />
      )}
      <Badge
        color="primary"
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            fontSize: 12,
            height: 25,
            minWidth: 25,
            backgroundColor: "red",
          },
        }}
        badgeContent={unread}
      ></Badge>
    </div>
  );
}

export default Category;

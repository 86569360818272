import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, Autocomplete, TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReportsLeftBar from "./ReportsLeftBar";
import ReportEditor from "./ReportEditor";

function WriteReportView() {
  const { t } = useTranslation(); // Using translation hook
  const [typeActivities, setTypeActivities] = useState([]);
  const [typeActivity, setTypeActivity] = useState(null);
  const [restaurant, setRestaurant] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState(null);
  const [reportById, setReportById] = useState([]);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const fetchActivities = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Report/GetActivities`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setTypeActivities(data);
    };

    const fetchRestaurants = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();

      const headOffice = {
        noEmplacement: 1,
        nomEmplacement: "Bureau Chef / Head Office",
      };
      setRestaurants([headOffice, ...data]);
    };

    const fetchReportsByUser = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/Report/GetReportsByUsername/${userState.username}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => results.json())
        .then((data) => setReports(data));
    };

    fetchActivities();
    fetchRestaurants();
    fetchReportsByUser();
  }, [userState.token, report, reportById]);

  useEffect(() => {
    if (typeActivity) {
      const fetchQuestions = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Report/GetQuestions/${typeActivity.id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );
        const data = await response.json();
        setQuestions(data);
      };
      fetchQuestions();
    }
  }, [typeActivity, userState.token]);

  useEffect(() => {
    if (report && report.idActivite && report.idTypeActivite) {
      const fetchReport = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/Report/GetUncompletedReport/${report.idActivite}/${report.idTypeActivite}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userState.token}`,
              },
            }
          );

          const data = await response.json();
          console.log(data); // Debugging fetched report data
          setTypeActivity(
            typeActivities.find(
              (activity) => activity.id === data.idTypeActivite
            )
          );
          setRestaurant(
            restaurants.find(
              (resto) => resto.noEmplacement === data.idEmplacement
            ).nomEmplacement
          );
          setReportById(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchReport();
    }
  }, [report, userState.token]);

  const handleChangeTypeActivities = (event, newValue) => {
    const selectedActivity = typeActivities.find(
      (activity) =>
        (userState.lang === "EN" ? activity.textEn : activity.textFr) ===
        newValue
    );
    setTypeActivity(selectedActivity);
  };

  const handleChangeRestaurant = (event, newValue) => {
    setRestaurant(newValue);
  };

  return (
    <>
      <Box>
        {/* Type of Report Dropdown */}
        <Box sx={{ display: "flex" }}>
          <Box sx={{ minWidth: "20%" }}>
            <ReportsLeftBar reports={reports} setReport={setReport} />
          </Box>
          <Box sx={{ ml: 2, width: "80%" }}>
            <FormControl fullWidth>
              <Autocomplete
                id={t("Report")}
                options={typeActivities.map((activity) =>
                  userState.lang === "EN" ? activity.textEn : activity.textFr
                )}
                onChange={handleChangeTypeActivities}
                sx={{ marginRight: "10px", marginTop: "15px" }}
                value={
                  typeActivity
                    ? userState.lang === "EN"
                      ? typeActivity.textEn
                      : typeActivity.textFr
                    : ""
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("Report")} />
                )}
              />
            </FormControl>

            {/* Location Dropdown */}
            <FormControl fullWidth>
              <Autocomplete
                id={t("Location")}
                options={restaurants.map((resto) => resto.nomEmplacement)}
                onChange={handleChangeRestaurant}
                sx={{ marginRight: "10px", marginTop: "15px" }}
                value={restaurant || ""}
                renderInput={(params) => (
                  <TextField {...params} label={t("Location")} />
                )}
              />
            </FormControl>
            <ReportEditor
              key={report ? report.id : "no-report"}
              report={reportById}
              typeActivity={typeActivity}
              restaurant={restaurant}
              setRestaurant={setRestaurant}
              setTypeActivity={setTypeActivity}
              setReport={setReportById}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default WriteReportView;

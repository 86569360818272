import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { VERSION } from "./components/Common/tools/Version";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme();

root.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);
if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "preprod"
) {
  const cssUrl = document.querySelector('link[rel="stylesheet"]').href;
  const jsUrl = document.querySelector('script[src*="chunk"]').src;
  document.querySelector(
    'link[rel="stylesheet"]'
  ).href = `${cssUrl}?v=${VERSION}`;
  document.querySelector('script[src*="chunk"]').src = `${jsUrl}?v=${VERSION}`;
}

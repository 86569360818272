import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Svg,
  Line,
  View,
  Font,
} from "@react-pdf/renderer";

const date = Date.now();
const author = "jlabarre";
const restaurant = "St-Jerome";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    paddingTop: 40,
    paddingBottom: 60,
    paddingHorizontal: 40,
    fontSize: 12,
    lineHeight: 1.5,
    position: "relative",
  },
  header: {
    borderBottom: 1,
    paddingBottom: 10,
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerCenter: {
    flexGrow: 1,
    textAlign: "center",
  },
  headerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  headerText: {
    fontSize: 8,
    marginBottom: 3,
  },
  title: {
    fontSize: 20,
    fontFamily: "Helvetica-Bold",
    margin: "0 0 0 20px",
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 10,
    borderBottom: 1,
    paddingBottom: 5,
    fontFamily: "Helvetica-Bold",
  },
  mandate: {
    marginBottom: 10,
  },
  mandateTitle: {
    fontSize: 16,
    marginBottom: 5,
    fontFamily: "Helvetica-Bold",
  },
  text: {
    marginBottom: 5,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 10,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 40,
    right: 40,
    borderTop: 1,
    paddingTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerText: {
    fontSize: 12,
  },
  logo: {
    width: 50,
    height: 50,
  },
});

export const MyDocument = ({ report, userLang }) => {
  const Header = ({ report, userLang }) => (
    <View style={styles.header}>
      <View style={styles.headerLeft}>
        <Image style={styles.logo} src="/CORA_SOLEIL.png" />
      </View>
      <View style={styles.headerCenter}>
        <Text style={styles.title}>
          {userLang == "EN" ? report.titleRepportEn : report.titleRepportFr}
        </Text>
      </View>
      <View style={styles.headerRight}>
        <Text style={styles.headerText}>Date: {report.dateComplete}</Text>
        <Text style={styles.headerText}>
          Restaurant: {report.nomEmplacement}
        </Text>
        <Text style={styles.headerText}>Author: {report.username}</Text>
      </View>
    </View>
  );

  const Footer = ({ pageNumber, report }) => (
    <View style={styles.footer}>
      <Text style={styles.footerText}>{report.dateComplete}</Text>
      <Text style={styles.footerText}>Page {pageNumber}</Text>
    </View>
  );

  return (
    <Document>
      <Page style={styles.page}>
        <Header report={report} userLang={userLang} />

        {report?.listeQuestions?.map((q) => (
          <View key={q.id} style={styles.section}>
            <Text style={[styles.boldText, { fontSize: 18 }]}>
              {userLang === "EN" ? q.textEn : q.textFr}
            </Text>

            {/* Mapping through responses with null checks */}
            {Array.isArray(report.listeReponses) &&
              report.listeReponses
                .filter((rep) => rep && rep.idQuestion === q.id) // Ensure rep exists and matches the question ID
                .map((rep) => (
                  <View key={rep.id}>
                    <Text style={styles.text}>
                      {rep?.commentaire || "No comment available"}
                    </Text>
                  </View>
                ))}

            {/* Rendering child questions, if available */}
            {q.childQuestions && q.childQuestions.length > 0 && (
              <View style={styles.separator}>
                {q.childQuestions.map((qc) => (
                  <View key={qc.id} style={styles.section}>
                    <Text style={styles.boldText}>
                      {userLang === "EN" ? qc.textEn : qc.textFr}
                    </Text>

                    {/* Mapping child question responses */}
                    {Array.isArray(report.listeReponses) &&
                      report.listeReponses
                        .filter((rep) => rep && rep.idQuestion === qc.id)
                        .map((rep) => (
                          <View key={rep.id}>
                            <Text style={styles.text}>
                              {rep?.commentaire || "No comment available"}
                            </Text>
                          </View>
                        ))}
                  </View>
                ))}
              </View>
            )}
          </View>
        ))}

        <Footer pageNumber={1} report={report} />
      </Page>
    </Document>
  );
};

export default MyDocument;

import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import AddNewRight from "./AddNewRight";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GroupManagement from "./Tabs/GroupManagement";
import InternalUserManagement from "./Tabs/InternalUserManagement";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function AccessRightManagement() {
  const [accessRight, setAccessRight] = useState(null);
  const [accessRightList, setAccessRightList] = useState([]);

  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["Employee"], ["Common"]);
  const userState = useSelector((state) => state.user);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeAccessRight = (e, newValue) => {
    setAccessRight(newValue);
  };

  const GetAccessRight = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Employee/GetAccessRight`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );
      const data = await response.json();
      setAccessRightList(data);
    } catch (error) {
      console.error("Error fetching access rights:", error);
    }
  };

  useEffect(() => {
    GetAccessRight();
  }, []);

  return (
    <>
      <Autocomplete
        id="access-right-autocomplete"
        options={accessRightList}
        getOptionLabel={(option) => option.nom || ""}
        onChange={(e, newValue) => handleChangeAccessRight(e, newValue)}
        value={accessRight || null}
        renderInput={(params) => <TextField {...params} label="Access Right" />}
        sx={{ marginRight: "10px" }}
      />
      <Button onClick={handleOpen}>{t("NewAccessRight")}</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="ArticleProperties__Modal">
          <AddNewRight />
        </Box>
      </Modal>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Gestion des groupes" {...a11yProps(0)} />
          <Tab label="Gestion user interne" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GroupManagement accessRight={accessRight} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <InternalUserManagement accessRight={accessRight} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </>
  );
}
export default AccessRightManagement;

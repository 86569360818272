import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CustomAlert from "../Components/CustomAlert";

export function Profil() {
  const { t } = useTranslation(["Login", "MFA", "Common"]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [isInternal, setIsInternal] = useState(true);
  const [username, setUsername] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [alertOpen, setAlertOpen] = useState(false); // Control alert visibility
  const [alertMessage, setAlertMessage] = useState(""); // Set alert message
  const [alertType, setAlertType] = useState("success"); // Set alert type

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);

  const userState = useSelector((state) => state.user);

  const getUserInfo = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/employee/getEmploye/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => results.json())
      .then((data) => {
        setUserInfo(data);
        if (data.email) {
          setEmail(data.email);
        }
        if (data.noTelephoneCellulaire) {
          setPhoneNumber(data.noTelephoneCellulaire);
        }
      });
  };

  const EmployeIsInterne = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/employee/getIsInternal/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => results.json())
      .then((data) => {
        setIsInternal(data);
      });
  };

  useEffect(() => {
    getUserInfo();
    EmployeIsInterne();
  }, []);

  const handleSave = async () => {
    const dataUser = {
      username: userState.username,
      email: email,
      phoneNumber: phoneNumber,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/employe/SaveEmploye`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(dataUser),
        }
      );

      if (response.ok) {
        setAlertMessage("Profile saved successfully!");
        setAlertType("success");
        setAlertOpen(true);
      } else {
        setAlertMessage("Failed to save profile.");
        setAlertType("error");
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage("An error occurred while saving profile.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleNewPassword = async () => {
    if (newPassword1 !== newPassword2) {
      setAlertMessage("Passwords do not match.");
      setAlertType("error");
      setAlertOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/SaveNewPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({
            username,
            password: newPassword1,
            currentPassword: currentPassword,
          }),
        }
      );
      var data = await response.text();
      if (response.ok) {
        setAlertMessage("Password changed successfully!");
        setAlertType("success");
        setAlertOpen(true);
        setModalOpen(false); // Close the modal on success
      } else {
        setAlertMessage(data);
        setAlertType("error");
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage("An error occurred while resetting the password.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#FFBC0F" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Info Profil")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label={t("Username")}
            name="username"
            disabled={true}
            value={userState.username}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t("Email")}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={isInternal}
          />
          <TextField
            margin="normal"
            fullWidth
            name="phoneNumber"
            label={t("PhoneNumber")}
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          />

          <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
            {!isInternal && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => setModalOpen(true)}
              >
                {t("ChangePassword")}
              </Button>
            )}
            <Button variant="contained" fullWidth onClick={handleSave}>
              {t("Common:Save")}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Password Change Modal */}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t("ChangePassword")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="currentPassword"
            label={t("MFA:CurrentPassword")}
            type="password"
            fullWidth
            variant="standard"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="newPassword1"
            label={t("MFA:NewPassword")}
            type="password"
            fullWidth
            variant="standard"
            value={newPassword1}
            onChange={(e) => setNewPassword1(e.target.value)}
          />
          <TextField
            margin="dense"
            id="newPassword2"
            label={t("MFA:RepeatNewPassword")}
            type="password"
            fullWidth
            variant="standard"
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            {t("Common:Cancel")}
          </Button>
          <Button onClick={handleNewPassword} color="primary">
            {t("Common:Save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Custom Alert for Feedback */}
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        typeMessage={alertType}
        closeMessage={setAlertOpen}
      />
    </Container>
  );
}

export default Profil;

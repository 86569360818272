import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export function ForgotUsername() {
  const [activeStep, setActiveStep] = useState(0);
  const { t, i18n } = useTranslation(["MFA", "Common"]);
  const [skipped, setSkipped] = useState(new Set());
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); // Success or error message

  const userState = useSelector((state) => state.user);

  const steps = [t("EnterEmail"), t("FinalInformation")];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const getUsername = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/login/GetUsername/${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
      } else {
        const errorText = await response.text();
      }
    } catch (error) {
      console.error("Error during getUsername request:", error);
      setMessage("An error occurred while retrieving the username.");
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      getUsername();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setMessage("");
    setEmail("");
    window.location.reload();
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you're finished
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 1,
                color: message.includes("Error") ? "red" : "green",
              }}
            >
              {message}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleClose}>{t("Common:Close")}</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {t("forgotUsernameInstruction")}
                </Typography>
                <TextField
                  style={{ width: "300px", margin: "5px" }}
                  type="text"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {t("forgotUsernameInstructionStep2")}
                </Typography>
                {message && (
                  <Typography
                    sx={{ color: message.includes("Error") ? "red" : "green" }}
                  >
                    {message}
                  </Typography>
                )}
              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("Common:Back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1
                  ? t("Common:Finish")
                  : t("Common:Next")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}

export default ForgotUsername;
